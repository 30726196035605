import { React, useEffect, useState, createRef } from 'react'
import QRCode from 'qrcode'
import './style.css';
import logo from '../../assets/images/logo2.png'
import { useScreenshot } from 'use-react-screenshot';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { TOAST } from '../../utils/Helpers';

const OrderBill = ({ receiptVisibleInfos, printerConnexionMode, printers, paperSize, copies, order }) => {

    useEffect(() => {
        generateQrCodeUri()
    }, [])

    useEffect(() => {
        setOrderToPrint(order)
    }, [order])

    const [qrcodeUri, setQrCodeUri] = useState("")
    const [orderToPrint, setOrderToPrint] = useState(null)
    const [image, takeScreenShot] = useScreenshot();
    const ref = createRef(null)
    const [billNumber, setBillNumber] = useState("")
    const [clientName, setClientName] = useState("")
    const [clientAddress, setClientAddress] = useState("")
    const [clientPhoneNumber, setClientPhoneNumber] = useState("")
    const [clientTVANumber, setClientTVANumber] = useState("")

    const dispatch = useDispatch()

    const generateQrCodeUri = async () => {
        try {
            let uri = await QRCode.toDataURL("http://maps.apple.com/maps?daddr=" + orderToPrint?.shop.latitude + "," + orderToPrint?.shop.longitude + "")
            setQrCodeUri(uri)
        } catch (err) {
        }
    }

    async function printOrderBill() {

        let isUsbPort = printerConnexionMode === "USB"

        if (isUsbPort) {
            let imagedata = await takeScreenShot(ref.current)
            let usbData = (imagedata + "#" + 1)
            window.location.href = "#print-order-receipt#" + usbData
        } else {
            if (printers.length === 0) {
                TOAST("Veuillez ajouter une imprimante", "error")
                return
            }
            printers.map(async (printer) => {
                if (printer.ipAddress === "") {
                    TOAST("Veuillez ajouter l'adresse ip de " + printer.name, "error")
                    return;
                }
                let imagedata = await takeScreenShot(ref.current)
                let ethernetData = (imagedata + "#" + 1 + "#" + printer.ipAddress)
                window.location.href = "#print-order-receipt#" + ethernetData + "#" + ref.current.offsetHeight

            })
        }
    }

    function getSupplementQuantity(orderItem, supplementId) {
        let supplementQts = orderItem.ingredientsQts.filter(item => item._id === supplementId)
        return supplementQts.length > 0 && supplementQts[0].quantity > 1 ? (supplementQts[0].quantity + "x ") : ""
    }

    function getCategoryName(item) {
        let isOneItemWithWeight = false
        item.items.forEach(product => {
            if (product.product?.isProductWithWeight === true) {
                isOneItemWithWeight = true
            }
        });
        return isOneItemWithWeight
            ? (item.category.indexOf(" - ") === -1 ? item.category : item.category.split(" - ")[0])
            : item.category
    }

    if (printerConnexionMode === "USB") {
        return <div className='flex gap-5'>

            <div className='flex-1'>

                <p className="text-xl my-2">Numéro de la facture</p>
                <input
                    type='number'
                    value={billNumber}
                    onChange={(e) => setBillNumber(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <p className="text-xl my-2">Nom de client</p>
                <input
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <p className="text-xl my-2">Adresse de client</p>
                <input
                    value={clientAddress}
                    onChange={(e) => setClientAddress(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <p className="text-xl my-2">Téléphone de client</p>
                <input
                    value={clientPhoneNumber}
                    type='mobile'
                    onChange={(e) => setClientPhoneNumber(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <p className="text-xl my-2">N° TVA de client</p>
                <input
                    value={clientTVANumber}
                    onChange={(e) => setClientTVANumber(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <button
                    className="bg-black text-white font-semibold text-xl w-full h-[55px] uppercase"
                    onClick={() => printOrderBill()}>
                    Imprimer la facture
                </button>

            </div>

            <div className='flex flex-col pb-7 items-center' style={{ backgroundColor: 'white', width: paperSize === 58 ? 390 : 570 }} ref={ref}>

                <p className='text-[40px] font-bold' style={{ fontWeight: 'bold', color: 'black' }}>Facture</p>
                <p className='text-[25px] text-center' style={{ fontWeight: 'bold', color: 'black' }}>N° {billNumber}</p>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    width: '100%'
                }}>
                    <div style={{ flex: 1 }}>
                        <p className='text-[25px] font-semibold' style={{ fontWeight: 'bold', color: 'black' }}>Vendeur</p>
                        <p className='text-[22px]' style={{ fontWeight: 'bold', color: 'black' }}>{orderToPrint?.shop?.name}</p>
                        <p className='text-[22px]' style={{ fontWeight: 'bold', color: 'black' }}>{orderToPrint?.shop?.address}, {orderToPrint?.shop?.city}</p>
                        <p className='text-[22px]' style={{ fontWeight: 'bold', color: 'black' }}>{orderToPrint?.shop?.mobile}</p>
                        <p className='text-[22px]' style={{ fontWeight: 'bold', color: 'black' }}>N° TVA: {orderToPrint?.shop?.vatNumber}</p>

                    </div>
                    <div style={{ flex: 0.1 }} />
                    <div style={{ flex: 1 }}>
                        <p className='text-[25px] text-right font-semibold' style={{ fontWeight: 'bold', color: 'black' }}>Client</p>
                        <p className='text-[22px] text-right' style={{ fontWeight: 'bold', color: 'black' }}>{clientName}</p>
                        <p className='text-[22px] text-right' style={{ fontWeight: 'bold', color: 'black' }}>{clientAddress}</p>
                        <p className='text-[22px] text-right' style={{ fontWeight: 'bold', color: 'black' }}>{clientPhoneNumber}</p>
                        <p className='text-[22px] text-right' style={{ fontWeight: 'bold', color: 'black' }}>N° TVA: <span className='font-medium'  style={{ fontWeight: 'bold', color: 'black' }}>{clientTVANumber}</span></p>
                    </div>
                </div>

                <p className='text-[40px] font-bold' style={{ fontWeight: 'bold', color: 'black' }}>{orderToPrint?.shop?.name}</p>
                <p className='text-[25px] text-center' style={{ fontWeight: 'bold', color: 'black' }}>{orderToPrint?.shop?.address}, {orderToPrint?.shop?.postalCode}</p>
                <p className='text-[25px]' style={{ fontWeight: 'bold', color: 'black' }}>Tél: {orderToPrint?.shop?.mobile}</p>
                <p className='text-[25px] font-medium mt-3' style={{ fontWeight: 'bold', color: 'black' }}>{moment(orderToPrint?.createdAt).format("DD MMM YYYY HH:mm")}</p>

                {orderToPrint?.items?.map((item, index) => <>
                    <p className='text-[27px] font-semibold w-full text-left px-5 mt-5' style={{ fontWeight: 'bold', color: 'black' }}>{getCategoryName(item)}</p>
                    {item.items.map((product, index) => (
                        <>
                            <div className='flex mt-1 justify-between px-8 w-full'>
                                <p className='text-[25px] font-medium' style={{ fontWeight: 'bold', color: 'black' }}>{product.quantity} {(!product.product?.isProductWithWeight ? product.product?.name : (product.product?.name + " - " + product.size)) || product.menu?.choices[0]?.name}</p>
                                <p className='text-[25px] font-medium' style={{ fontWeight: 'bold', color: 'black' }}>{product.price === 0 ? "Gratuit" : "€" + product.price}</p>
                            </div>

                            {product.baseChoices?.map((baseChoice, index) =>
                                <p className='text-[24px] px-12 w-full text-left' style={{ fontWeight: 'bold', color: 'black' }}>+ {baseChoice.choice}</p>
                            )}

                            {product.productBaseChoices?.map((baseChoice, index) =>
                                <p className='text-[24px] px-12 w-full text-left' style={{ fontWeight: 'bold', color: 'black' }}>+ {baseChoice.choice}</p>
                            )}

                            {product.ingredients?.map((ingredient, index) =>
                                <p className='text-[24px] px-12 w-full text-left' style={{ fontWeight: 'bold', color: 'black' }}>+ {getSupplementQuantity(product, ingredient._id)}{ingredient.name}</p>
                            )}
                        </>
                    ))}
                </>
                )}

                <div className='flex mt-6 justify-between px-8 w-full'>
                    <p className='text-[24px]' style={{ fontWeight: 'bold', color: 'black' }}>Sous total</p>
                    <p className='text-[24px]' style={{ fontWeight: 'bold', color: 'black' }}>€{parseFloat(orderToPrint?.subtotal?.toFixed(2))}</p>
                </div>

                {orderToPrint?.comment !== "" && orderToPrint?.comment !== "0" && orderToPrint?.comment !== "0.00" &&
                    <div className='flex mt-2 justify-between px-8 w-full'>
                        <p className='text-[24px]' style={{ fontWeight: 'bold', color: 'black' }}>Réduction</p>
                        <p className='text-[24px]' style={{ fontWeight: 'bold', color: 'black' }}>-€{parseFloat(orderToPrint?.comment).toFixed(2)}</p>
                    </div>
                }
                <div className='flex mt-2 justify-between px-8 w-full'>
                    <p className='text-[27px] font-semibold' style={{ fontWeight: 'bold', color: 'black' }}>TOTAL</p>
                    <p className='text-[27px] font-semibold' style={{ fontWeight: 'bold', color: 'black' }}>€{parseFloat(orderToPrint?.total?.toFixed(2))}</p>
                </div>

                <p className='mt-5 font-medium text-[34px]' style={{ fontWeight: 'bold', color: 'black' }}>{orderToPrint?.paymentMethod ? ("Paiement " + orderToPrint?.paymentMethod) : "Non payée".toUpperCase()}</p>
                <div className='flex my-3'>
                    <img alt="" src={logo} height={25} width={25} style={{ objectFit: 'contain' }} />
                    <p className='text-[24px] font-normal ml-3' style={{ fontWeight: 'bold', color: 'black' }}>Powerd by B-TIP</p>
                </div>
            </div>
        </div>

    }

    return (
        <div className='flex gap-5'>

            <div className='flex-1'>

                <p className="text-xl my-2">Numéro de la facture</p>
                <input
                    type='number'
                    value={billNumber}
                    onChange={(e) => setBillNumber(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <p className="text-xl my-2">Nom de client</p>
                <input
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <p className="text-xl my-2">Adresse de client</p>
                <input
                    value={clientAddress}
                    onChange={(e) => setClientAddress(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <p className="text-xl my-2">Téléphone de client</p>
                <input
                    value={clientPhoneNumber}
                    type='mobile'
                    onChange={(e) => setClientPhoneNumber(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <p className="text-xl my-2">N° TVA de client</p>
                <input
                    value={clientTVANumber}
                    onChange={(e) => setClientTVANumber(e.target.value)}
                    className='w-full text-lg py-3 px-5 border-2'
                />

                <button
                    className="bg-black text-white font-semibold text-xl w-full h-[55px] uppercase"
                    onClick={() => printOrderBill()}>
                    Imprimer la facture
                </button>

            </div>

            <div className='flex flex-col pb-7 items-center' style={{ backgroundColor: 'white', width: paperSize === 58 ? 390 : 570 }} ref={ref}>

                <p className='text-[40px] font-bold'>Facture</p>
                <p className='text-[25px] text-center'>N° {billNumber}</p>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    width: '100%'
                }}>
                    <div style={{ flex: 1 }}>
                        <p className='text-[25px] font-semibold'>Vendeur</p>
                        <p className='text-[22px]'>{orderToPrint?.shop?.name}</p>
                        <p className='text-[22px]'>{orderToPrint?.shop?.address}, {orderToPrint?.shop?.city}</p>
                        <p className='text-[22px]'>{orderToPrint?.shop?.mobile}</p>
                        <p className='text-[22px]'>N° TVA: {orderToPrint?.shop?.vatNumber}</p>

                    </div>
                    <div style={{ flex: 0.1 }} />
                    <div style={{ flex: 1 }}>
                        <p className='text-[25px] text-right font-semibold'>Client</p>
                        <p className='text-[22px] text-right'>{clientName}</p>
                        <p className='text-[22px] text-right'>{clientAddress}</p>
                        <p className='text-[22px] text-right'>{clientPhoneNumber}</p>
                        <p className='text-[22px] text-right'>N° TVA: <span className='font-medium'>{clientTVANumber}</span></p>
                    </div>
                </div>

                <p className='text-[40px] font-bold'>{orderToPrint?.shop?.name}</p>
                <p className='text-[25px] text-center'>{orderToPrint?.shop?.address}, {orderToPrint?.shop?.postalCode}</p>
                <p className='text-[25px]'>Tél: {orderToPrint?.shop?.mobile}</p>
                <p className='text-[25px] font-medium mt-3'>{moment(orderToPrint?.createdAt).format("DD MMM YYYY HH:mm")}</p>

                {orderToPrint?.items?.map((item, index) => <>
                    <p className='text-[27px] font-semibold w-full text-left px-5 mt-5 '>{getCategoryName(item)}</p>
                    {item.items.map((product, index) => (
                        <>
                            <div className='flex mt-1 justify-between px-8 w-full'>
                                <p className='text-[25px] font-medium'>{product.quantity} {(!product.product?.isProductWithWeight ? product.product?.name : (product.product?.name + " - " + product.size)) || product.menu?.choices[0]?.name}</p>
                                <p className='text-[25px] font-medium'>{product.price === 0 ? "Gratuit" : "€" + product.price}</p>
                            </div>

                            {product.baseChoices?.map((baseChoice, index) =>
                                <p className='text-[24px] px-12 w-full text-left'>+ {baseChoice.choice}</p>
                            )}

                            {product.productBaseChoices?.map((baseChoice, index) =>
                                <p className='text-[24px] px-12 w-full text-left'>+ {baseChoice.choice}</p>
                            )}

                            {product.ingredients?.map((ingredient, index) =>
                                <p className='text-[24px] px-12 w-full text-left'>+ {getSupplementQuantity(product, ingredient._id)}{ingredient.name}</p>
                            )}

                            {product.description && product.description !== "" && <p className='text-[24px] px-12 w-full mt-1 text-left' style={{ fontStyle: 'italic' }}>{product.description}</p>}
                        </>
                    ))}
                </>
                )}

                <div className='flex mt-6 justify-between px-8 w-full'>
                    <p className='text-[24px]'>Sous total</p>
                    <p className='text-[24px]'>€{parseFloat(orderToPrint?.subtotal?.toFixed(2))}</p>
                </div>

                {orderToPrint?.comment !== "" && orderToPrint?.comment !== "0" && orderToPrint?.comment !== "0.00" &&
                    <div className='flex mt-2 justify-between px-8 w-full'>
                        <p className='text-[24px]'>Réduction</p>
                        <p className='text-[24px]'>-€{parseFloat(orderToPrint?.comment).toFixed(2)}</p>
                    </div>
                }
                <div className='flex mt-2 justify-between px-8 w-full'>
                    <p className='text-[27px] font-semibold'>TOTAL</p>
                    <p className='text-[27px] font-semibold'>€{parseFloat(orderToPrint?.total?.toFixed(2))}</p>
                </div>

                {receiptVisibleInfos?.paymentMethod && <p className='mt-5 font-medium text-[34px]'>{orderToPrint?.paymentMethod ? ("Paiement " + orderToPrint?.paymentMethod) : "Non payée".toUpperCase()}</p>}
                {receiptVisibleInfos?.addressQrCode && orderToPrint?.deliveryMethod === "Livraison" && <img src={qrcodeUri} height={200} width={200} alt="" />}
                <div className='flex my-3'>
                    <img alt="" src={logo} height={25} width={25} style={{ objectFit: 'contain' }} />
                    <p className='text-[24px] font-normal ml-3'>Powerd by B-TIP</p>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        receiptVisibleInfos: state.parameters.printerParameters.receiptVisibleInfos,
        printerConnexionMode: state.parameters.printerParameters.printerConnexionMode,
        printerEthernetIpAddress: state.parameters.printerParameters.printerEthernetIpAddress,
        paperSize: state.parameters.printerParameters.receiptPaperSize,
        printers: state.parameters.printerParameters.printers,
        copies: state.parameters.printerParameters.copies,
        orderToPrint: state.order.orderToPrint
    }
}

export default connect(mapStateToProps)(OrderBill);