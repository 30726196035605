import { url } from "../../config/BaseUrl";
import { TOAST } from "../../utils/Helpers";

export const getShopRooms = () => {

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    return (dispatch, getState) => {
        url.get(`rooms/all/${localStorage.getItem("shop_id")}`, { headers: headers })
            .then(response => {
                if (response.status === 200) {
                    let rooms = response.data
                    dispatch({ type: "SET_ROOMS", rooms });
                }
            })
            .catch(err => { console.log(err) });
    }
}

export const addRoom = (data) => {

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    return (dispatch, getState) => {
        url.post(`rooms/`, data, { headers: headers })
            .then(response => {
                if (response.status === 200) {
                    url.get(`rooms/all/${localStorage.getItem("shop_id")}`, { headers: headers })
                        .then(response => {
                            if (response.status === 200) {
                                let rooms = response.data
                                dispatch({ type: "SET_ROOMS", rooms });
                            }
                        })
                        .catch(err => { console.log(err) });
                }
            })
            .catch(err => { console.log(err) });
    }
}

export const updateRoom = (data, idRoom) => {

}

export const removeRoom = (id) => {

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    return (dispatch, getState) => {
        url.delete("rooms/" + id, { headers: headers })
            .then(response => {
                if (response.status === 200) {
                    url.get(`rooms/all/${localStorage.getItem("shop_id")}`, { headers: headers })
                        .then(response => {
                            if (response.status === 200) {
                                let rooms = response.data
                                dispatch({ type: "SET_ROOMS", rooms });
                            }
                        })
                        .catch(err => { console.log(err) });
                }
            })
            .catch(err => { console.log(err) });
    }
}