import { React, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import '../componants/modals/style.css';
import TestReceipt from '../componants/modals/TestReceipt';
import { setPrinterParameters } from '../store/actions/ParametersActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faClose, faEye, faRemove, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TOAST } from '../utils/Helpers';

const PrinterSettings = ({ printerParameters, isWaiter }) => {

    const dispatch = useDispatch()
    const [defaultIpAddress, setDefaultIpAddress] = useState("")
    const [isShowReceiptModal, showReceiptModal] = useState(false)

    function setDefaultPaperSize(size) {

        switch (size) {
            case 58:
                window.localStorage.setItem("receiptPaperSize", "58")
                dispatch(setPrinterParameters({ ...printerParameters, receiptPaperSize: 58 }))
                break
            case 80:
                window.localStorage.setItem("receiptPaperSize", "80")
                dispatch(setPrinterParameters({ ...printerParameters, receiptPaperSize: 80 }))
                break
            default:
                break
        }
    }

    function savePrinterEthernetAddress() {

        if (!isValidAddressIp(printerParameters?.printerEthernetIpAddress)) {
            return false
        }
        updateLocalStorageParamValue("ethernetIpAddress", printerParameters?.printerEthernetIpAddress)
        setDefaultIpAddress(printerParameters?.printerEthernetIpAddress)
    }

    function isValidAddressIp(ipAddress) {
        return /^(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)$/.test(ipAddress)
    }

    async function verifyEthernetPrinter(ipAddress) {

        if (!isValidAddressIp(ipAddress)) {
            TOAST("Adresse ip non valide", "error")
            return
        }

        window.location.href = "#verify-ethernet-connexion#" + ipAddress
    }

    function updateLocalStorageParamValue(paramName, value) {
        localStorage.setItem(paramName, value)
    }

    function updateLocalStoragereceiptVisibleInfos(data) {
        localStorage.setItem("receiptVisibleInfos", JSON.stringify(data))
    }

    async function updateCopiesCount(isIncrement) {

        if ((!isIncrement && printerParameters?.copies - 1 > 0) || (isIncrement && printerParameters?.copies + 1 <= 5)) {
            let _copies = isIncrement ? printerParameters?.copies + 1 : printerParameters?.copies - 1
            updateLocalStorageParamValue("copies", _copies)
            dispatch(setPrinterParameters({ ...printerParameters, copies: _copies }))
        }
    }

    async function updateMargin(isIncrement) {

        if ((!isIncrement && printerParameters?.margin - 5 >= 0) || (isIncrement)) {
            let margin = isIncrement ? printerParameters?.margin + 5 : printerParameters?.margin - 5
            updateLocalStorageParamValue("margin", margin)
            dispatch(setPrinterParameters({ ...printerParameters, margin: margin }))
        }
    }

    function addPrinter() {

        let _printers = [
            ...printerParameters?.printers, {
                name: ("Imprimante " + (printerParameters?.printers.length + 1)),
                ipAddress: ""
            }]
        updateLocalStorageParamValue("printers", JSON.stringify(_printers))
        dispatch(setPrinterParameters({ ...printerParameters, printers: _printers }))

    }

    function deletePrinter(name) {
        let _printers = printerParameters?.printers.filter(printer => printer.name !== name)
        updateLocalStorageParamValue("printers", JSON.stringify(_printers))
        dispatch(setPrinterParameters({ ...printerParameters, printers: _printers }))
    }

    function updatePrinterData(name, data) {

        let _printers = printerParameters?.printers.map(printer => {
            let object = name === printer.name ? data : printer
            console.log("object", object)
            return object
        })
        updateLocalStorageParamValue("printers", JSON.stringify(_printers))
        dispatch(setPrinterParameters({ ...printerParameters, printers: _printers }))
    }

    if (isWaiter) {
        return (
            <div className='w-full overflow-y-auto w-full h-[calc(100vh-165px)] scrollbar-none' style={{ backgroundColor: '#E8E7FB' }}>
                {!isWaiter &&
                    <div className="flex items-center w-full">
                        <h3 className="text-2xl font-semibold w-full p-5 ">
                            Réglages d'impression
                        </h3>
                    </div>
                }
                <Grid>
                    <div className=" flex flex-col pt-5">
                        {printerParameters?.printerConnexionMode === "ETHERNET" &&
                            <Card className='mx-5 mb-5 py-5'>
                                {printerParameters?.printers.map(printer => (
                                    <div className='flex px-5 gap-3 items-center mb-4'>

                                        <p className='font-normal text-xl'>Nom: </p>
                                        <input
                                            type="text"
                                            placeholder='Tapez le nom'
                                            value={printer.name}
                                            onChange={(e) => updatePrinterData(printer.name, { name: e.target.value, ipAddress: printer.ipAddress })}
                                            style={{ border: '1px solid #ccc', fontSize: 18, width: isWaiter ? 190 : null }}
                                            className='px-2 py-2'
                                        />

                                        <p className='font-normal text-xl'>Adresse IP : </p>
                                        <input
                                            type="tel"
                                            placeholder='ex: 192.168.1.255'
                                            value={printer.ipAddress}
                                            onChange={(e) => updatePrinterData(printer.name, { name: printer.name, ipAddress: e.target.value })}
                                            style={{ border: '1px solid #ccc', fontSize: 18, width: isWaiter ? 190 : null }}
                                            className='px-2 py-2'
                                        />

                                        <button
                                            className="bg-black text-white font-medium text-md px-5 mx-1 py-3 rounded"
                                            style={{ backgroundColor: '#E92D3F' }}
                                            onClick={() => verifyEthernetPrinter(printer.ipAddress)}>
                                            {"Vérifier la connexion".toUpperCase()}
                                        </button>

                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{ color: "red", height: 25, width: 25 }}
                                            onClick={() => deletePrinter(printer.name)}
                                        />
                                    </div>
                                ))}

                                <button
                                    className="mx-5 text-blac text-xl font-medium items-center flex gap-2 text-md px-5 py-3 rounded"
                                    style={{ border: '2px dashed #000' }}
                                    onClick={() => addPrinter()}>
                                    <FontAwesomeIcon icon={faAdd} style={{ color: "#000", height: 25, width: 25 }} />
                                    {"Ajouter une imprimante".toUpperCase()}
                                </button>

                            </Card>
                        }

                        <div className='flex px-5 items-center mb-4'>
                            <p className='font-normal text-xl'>Taille reçu : </p>
                            <div className='flex gap-1 mx-5'>
                                <button
                                    className={"text-black border w-full font-semibold uppercase text-xl py-2 px-10 " + (printerParameters?.receiptPaperSize === 58 ? "bg-black text-white" : "bg-transparent text-black")}
                                    type="button"
                                    onClick={() => setDefaultPaperSize(58)}>
                                    58mm
                                </button>
                                <button
                                    className={"text-black border w-full font-semibold uppercase text-xl py-2 px-10 " + (printerParameters?.receiptPaperSize === 80 ? "bg-black text-white" : "bg-transparent text-black")}
                                    type="button"
                                    onClick={() => setDefaultPaperSize(80)}>
                                    80mm
                                </button>
                            </div>
                        </div>
                    </div>
                    <button
                        className="bg-black gap-4 flex items-center text-white uppercase font-medium text-md px-5 ml-5 mt-5 py-3 rounded"
                        onClick={() => showReceiptModal(true)}>
                        <FontAwesomeIcon icon={faEye} style={{ color: "#fff", height: 25, width: 25 }} />
                        Visualiser le reçu
                    </button>
                </Grid>

                <Dialog
                    open={isShowReceiptModal}
                    onClose={() => showReceiptModal(false)}
                    PaperProps={{
                        style: {
                            minWidth: '50%',
                            maxWidth: '50%',
                        }
                    }}
                    className="modalWrapper">
                    <DialogTitle className='flex justify-end'>
                        <FontAwesomeIcon onClick={() => showReceiptModal(false)} icon={faClose} style={{ color: "#000", height: 25, width: 25 }} />
                    </DialogTitle>
                    <DialogContent>
                        <TestReceipt
                            receiptVisibleInfos={printerParameters?.receiptVisibleInfos}
                            printerConnexionMode={printerParameters?.printerConnexionMode}
                            printerEthernetIpAddress={printerParameters?.printerEthernetIpAddress}
                            paperSize={printerParameters?.receiptPaperSize}
                            printers={printerParameters?.printers}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (

        <div className='w-full overflow-y-auto w-[100vh] h-[calc(100vh-165px)] scrollbar-none' style={{ backgroundColor: '#fff' }}>
            {/* <div className="flex items-center w-full">
                <h3 className="text-2xl font-semibold w-full p-5 ">
                    Réglages d'impression
                </h3>
            </div> */}
            <Grid>
                <div className=" flex flex-col pt-5 bg-white">
                    <div className='flex px-5 items-center mb-4'>
                        <p className='font-normal text-xl'>Type de connexion : </p>
                        <div className='flex gap-1 mx-5'>
                            <button
                                className={"text-black border w-full font-semibold uppercase text-xl py-2 px-10 " + (printerParameters?.printerConnexionMode === "USB" ? "bg-black text-white" : "bg-transparent text-black")}
                                type="button"
                                onClick={() => {
                                    updateLocalStorageParamValue("printerConnexionMode", "USB")
                                    dispatch(setPrinterParameters({ ...printerParameters, printerConnexionMode: "USB" }))
                                }}>
                                USB
                            </button>

                            <button
                                className={"text-black border w-full font-semibold uppercase text-xl py-2 px-10 " + (printerParameters?.printerConnexionMode === "ETHERNET" ? "bg-black text-white" : "bg-transparent text-black")}
                                type="button"
                                onClick={() => {
                                    updateLocalStorageParamValue("printerConnexionMode", "ETHERNET")
                                    dispatch(setPrinterParameters({ ...printerParameters, printerConnexionMode: "ETHERNET" }))
                                }}>
                                ETHERNET
                            </button>
                        </div>
                    </div>

                    {printerParameters?.printerConnexionMode === "ETHERNET" &&
                        <Card className='mx-5 mb-5 py-5'>
                            {printerParameters?.printers.map(printer => (
                                <div className='flex px-5 gap-3 items-center mb-4'>

                                    <p className='font-normal text-xl'>Nom: </p>
                                    <input
                                        type='text'
                                        placeholder='Tapez le nom'
                                        value={printer.name}
                                        onChange={(e) => updatePrinterData(printer.name, { name: e.target.value, ipAddress: printer.ipAddress })}
                                        style={{ border: '1px solid #ccc', fontSize: 18 }}
                                        className='px-2 py-2'
                                    />

                                    <p className='font-normal text-xl'>Adresse IP : </p>
                                    <input
                                        type='text'
                                        placeholder='ex: 192.168.1.255'
                                        value={printer.ipAddress}
                                        onChange={(e) => updatePrinterData(printer.name, { name: printer.name, ipAddress: e.target.value })}
                                        style={{ border: '1px solid #ccc', fontSize: 18 }}
                                        className='px-2 py-2'
                                    />

                                    <button
                                        className="bg-black text-white font-medium text-md px-5 mx-1 py-3 rounded"
                                        style={{ backgroundColor: '#E92D3F' }}
                                        onClick={() => verifyEthernetPrinter(printer.ipAddress)}>
                                        {"Vérifier la connexion".toUpperCase()}
                                    </button>

                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ color: "red", height: 25, width: 25 }}
                                        onClick={() => deletePrinter(printer.name)}
                                    />
                                </div>
                            ))}

                            <button
                                className="mx-5 text-blac text-xl font-medium items-center flex gap-2 text-md px-5 py-3 rounded"
                                style={{ border: '2px dashed #000' }}
                                onClick={() => addPrinter()}>
                                <FontAwesomeIcon icon={faAdd} style={{ color: "#000", height: 25, width: 25 }} />
                                {"Ajouter une imprimante".toUpperCase()}
                            </button>

                        </Card>

                    }
                    <div className='flex px-5 items-center mb-4'>
                        <p className='font-normal text-xl'>Taille reçu : </p>
                        <div className='flex gap-1 mx-5'>
                            <button
                                className={"text-black border w-full font-semibold uppercase text-xl py-2 px-10 " + (printerParameters?.receiptPaperSize === 58 ? "bg-black text-white" : "bg-transparent text-black")}
                                type="button"
                                onClick={() => setDefaultPaperSize(58)}>
                                58mm
                            </button>
                            <button
                                className={"text-black border w-full font-semibold uppercase text-xl py-2 px-10 " + (printerParameters?.receiptPaperSize === 80 ? "bg-black text-white" : "bg-transparent text-black")}
                                type="button"
                                onClick={() => setDefaultPaperSize(80)}>
                                80mm
                            </button>
                        </div>
                    </div>

                    {/* <div className='flex px-5 items-center mb-4'>
                            <p className='font-normal text-xl'>Marge sur les côtés de reçu : </p>
                            <div className='flex gap-1 mx-5'>
                                <input
                                    type='number'
                                    placeholder='ex: 5'
                                    value={ipAddress}
                                    onChange={(e) => setIpAddress(e.target.value)}
                                    style={{ border: '1px solid #ccc', fontSize: 18 }}
                                    className='px-2 py-2'
                                />
                            </div>
                        </div> */}

                    <div className='flex px-5 items-center mb-4'>
                        <p className='font-normal text-xl'>Nombre de copies : </p>
                        <div className='flex gap-4 mx-5 items-center'>
                            <button className='border px-3 py-1 text-white bg-black' onClick={() => updateCopiesCount(false)}>-</button>
                            <p className='font-semibold text-xl'>{printerParameters?.copies}</p>
                            <button className='border px-3 py-1 text-white bg-black' onClick={() => updateCopiesCount(true)}>+</button>
                        </div>
                    </div>

                    <div className='flex px-5 items-center mb-4'>
                        <p className='font-normal text-xl'>Marge reçu : </p>
                        <div className='flex gap-4 mx-5 items-center'>
                            <button className='border px-3 py-1 text-white bg-black' onClick={() => updateMargin(false)}>-</button>
                            <p className='font-semibold text-xl'>{printerParameters?.margin}</p>
                            <button className='border px-3 py-1 text-white bg-black' onClick={() => updateMargin(true)}>+</button>
                        </div>
                    </div>

                    <p className='font-medium text-[26px] mt-4 px-5 mb-2'>Reçu</p>
                    <div className='flex flex-wrap mx-2 px-2 bg-gray-10'>
                        {/* <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.shopName} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, shopName: !printerParameters?.receiptVisibleInfos?.shopName } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, shopName: !printerParameters?.receiptVisibleInfos?.shopName }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Nom du restaurant: </p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.shopAddress} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, shopAddress: !printerParameters?.receiptVisibleInfos?.shopAddress } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, shopAddress: !printerParameters?.receiptVisibleInfos?.shopAddress }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Adresse du restaurant</p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.shopMobile} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, shopMobile: !printerParameters?.receiptVisibleInfos?.shopMobile } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, shopMobile: !printerParameters?.receiptVisibleInfos?.shopMobile }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>N° téléphone du restaurant</p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.orderReference} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, orderReference: !printerParameters?.receiptVisibleInfos?.orderReference } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, orderReference: !printerParameters?.receiptVisibleInfos?.orderReference }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Identifiant de la commande</p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.orderDate} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, orderDate: !printerParameters?.receiptVisibleInfos?.orderDate } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, orderDate: !printerParameters?.receiptVisibleInfos?.orderDate }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Date de création de la commde</p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.orderMode} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, orderMode: !printerParameters?.receiptVisibleInfos?.orderMode } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, orderMode: !printerParameters?.receiptVisibleInfos?.orderMode }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Mode de la commande</p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.tableNumber} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, tableNumber: !printerParameters?.receiptVisibleInfos?.tableNumber } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, tableNumber: !printerParameters?.receiptVisibleInfos?.tableNumber }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Numéro de la table</p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input disabled type="checkbox" checked className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Heure de livraison</p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input disabled type="checkbox" checked className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Adresse de livraison</p>
                        </div>
                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input disabled type="checkbox" checked className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>N° téléphone du client</p>
                        </div>

                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input disabled type="checkbox" checked className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Remarques</p>
                        </div>

                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input disabled type="checkbox" checked className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Produits</p>
                        </div>

                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.paymentMethod} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, paymentMethod: !printerParameters?.receiptVisibleInfos?.paymentMethod } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, paymentMethod: !printerParameters?.receiptVisibleInfos?.paymentMethod }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>Mode de paiement</p>
                        </div>

                        <div className='flex px-2 pt-5 gap-2 items-center'>
                            <input type="checkbox" checked={printerParameters?.receiptVisibleInfos?.addressQrCode} onChange={() => { dispatch(setPrinterParameters({ ...printerParameters, receiptVisibleInfos: { ...printerParameters?.receiptVisibleInfos, addressQrCode: !printerParameters?.receiptVisibleInfos?.addressQrCode } })); updateLocalStoragereceiptVisibleInfos({ ...printerParameters?.receiptVisibleInfos, addressQrCode: !printerParameters?.receiptVisibleInfos?.addressQrCode }) }} className='h-[22px] w-[22px]' />
                            <p className='font-normal text-lg'>QR code de l'adresse</p>
                        </div> */}
                    </div>
                </div>

                <button
                    className="bg-black gap-4 flex items-center text-white uppercase font-medium text-md px-5 ml-5 mt-5 py-3 rounded"
                    onClick={() => showReceiptModal(true)}>
                    <FontAwesomeIcon icon={faEye} style={{ color: "#fff", height: 25, width: 25 }} />
                    Visualiser le reçu
                </button>
            </Grid>

            <Dialog
                open={isShowReceiptModal}
                maxWidth='md'
                onClose={() => showReceiptModal(false)}
                className="modalWrapper">
                <DialogTitle className='flex justify-end'>
                    <FontAwesomeIcon onClick={() => showReceiptModal(false)} icon={faClose} style={{ color: "#000", height: 25, width: 25 }} />
                </DialogTitle>
                <DialogContent>
                    <TestReceipt
                        receiptVisibleInfos={printerParameters?.receiptVisibleInfos}
                        printerConnexionMode={printerParameters?.printerConnexionMode}
                        printerEthernetIpAddress={printerParameters?.printerEthernetIpAddress}
                        paperSize={printerParameters?.receiptPaperSize}
                        printers={printerParameters?.printers}
                        margin={printerParameters?.margin}
                    />
                </DialogContent>
            </Dialog>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        printerParameters: state.parameters.printerParameters,
        isWaiter: state.auth.isWaiter
    }
}

export default connect(mapStateToProps)(PrinterSettings);