const initState ={
    adresses:[],
   
} 
const SearchReducer = ( state =initState , action )=>{
    
    switch ( action.type ){
        case "GET_LOCALISATIONS":
            return {
                ...state,
                adresses : action.paylod.searsh,
                }
        default : return state;
    }

}

export default SearchReducer;