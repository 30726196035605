const initState = {
    categories: [],
    menus: [],
    supplements: []
}
const CategoriesReducer = (state = initState, action) => {

    switch (action.type) {
        case "GET_USER_CATEGORIES":

            let indexToMove = action.paylod.categories.findIndex(category => category.name === "Divers");
            if (indexToMove !== -1) {
                // Remove the category from its current position
                let categoryToMove = action.paylod.categories.splice(indexToMove, 1)[0];
                // Add the category at the beginning of the array
                action.paylod.categories.unshift(categoryToMove);
            }

            return {
                ...state,
                categories: action.paylod.categories,
            }
        case "GET_USER_MENUS":
            return {
                ...state,
                menus: action.paylod.menus,
            }
        case "GET_SUPPLEMENTS": {
            return {
                ...state,
                supplements: action.paylod.supplements,
            }
        }
        default: return state;
    }

}


export default CategoriesReducer;