const initState = {
    parameters: [],
    printerParameters: {
        copies: 1,
        margin: 0,
        isPrintQrCode: true,
        receiptPaperSize: 58,
        printerConnexionMode: 80,
        printerEthernetIpAddress: '',
        printers: [],
        receiptMarging: 0,
        receiptVisibleInfos: {
            shopName: true,
            shopAddress: true,
            shopMobile: true,
            orderReference: true,
            orderDate: true,
            orderMode: true,
            tableNumber: true,
            paymentMethod: true,
            addressQrCode: true
        }
    },
}
const ParametersReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_PARAMETERS":
            return { ...state, parameters: action.payload.parameters }
        case "SET_PRINTER_PARAMETERS":
            return { ...state, printerParameters: action.parameters }
        default:
            return state
    }
}

export default ParametersReducer;