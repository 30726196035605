import { url } from "../../config/BaseUrl";

export const getParameters = () => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    return (dispatch, getState) => {
        url.get(`parameters/${localStorage.getItem("shop_id")}`, { headers: headers })
            .then(response => {
                if (response.status === 200) {
                    dispatch({ type: "SET_PARAMETERS", payload: { parameters: response.data } });
                }
            })
            .catch(err => { console.log(err) });
    }
}

export const setPrinterParameters = (parameters) => {
    return {
        type: 'SET_PRINTER_PARAMETERS',
        parameters
    };
};