import React from 'react';
import useLongPress from '../../utils/useLongPress';

const CategoryButton = ({ categoryName, isActive, selectTvaCategory, categoryLength, selectCategory, isDisabled }) => {

  const onLongPress = () => {
    selectTvaCategory()
  };

  const onClick = () => {
    selectCategory()
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500
  };

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  return (
    <button
      className={`flex-shrink-0 whitespace-nowrap flex flex-col select-none w-full py-3 items-center text-center align-middle font-sans text-[15px] font-bold uppercase shadow-md shadow-blue-gray-500/10 transition-all hover:shadow-lg hover:shadow-blue-gray-500/20 focus:shadow-none ${
        isActive ? 'text-dark' : 'text-white'
      } ${isDisabled ? 'active:bg-primary active:text-white active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none' : ''}`}
      {...longPressEvent}
      style={{ backgroundColor: isActive ? '#E4EBE0' : 'transparent'}}
      disabled={isDisabled}
    >
      {categoryName}
      <span className="text-[13px] capitalize font-normal">{categoryLength} Produits</span>
    </button>
  );
}

export default CategoryButton;