export const setTabIndex = (index) => {
    return {
        type: 'SET_TAB_INDEX',
        index
    };
};

export const setScaleWeight = (scaleWeight) => {
    return {
        type: 'SET_SCALE_WEIGHT',
        scaleWeight
    };
};