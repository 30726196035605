const initState = {
  items: [],
  itemToEdit: null
};

// const cartReducer = (state = initState, action) => {
//   switch (action.type) {
//     case 'ADD_TO_CART':
//       const productToAdd = action.payload;
//       const productToAddProductID = productToAdd.product?._id;
//       const productToAddMenuID = productToAdd.menu?._id;

//       const existingProductIndex = state.cart.findIndex((item) => {
//         const itemProductID = item.product?._id;
//         const itemMenuID = item.menu?._id;
//         const sameProduct = itemProductID && productToAddProductID && itemProductID === productToAddProductID;
//         const sameMenu = itemMenuID && productToAddMenuID && itemMenuID === productToAddMenuID;
//         const sameSize = item?.size === productToAdd?.size;
//         const sameSupplements = JSON.stringify(item?.suplements) === JSON.stringify(productToAdd?.suplements);
//         const sameIngredientsQts = JSON.stringify(item?.ingredientsQts) === JSON.stringify(productToAdd?.ingredientsQts);
//         const sameProducts = JSON.stringify(item?.products) === JSON.stringify(productToAdd?.products);
//         if (item.size == "") {
//           {
//             return (sameProduct || sameMenu);
//           }
//         }
//         else {
//           return (sameProduct || sameMenu) && sameSize && (sameSupplements && sameIngredientsQts) && sameProducts;
//         }
//       });
//       if (existingProductIndex !== -1) {
//         const updatedCart = [...state.cart];
//         updatedCart[existingProductIndex].quantity += 1;

//         return {
//           ...state,
//           cart: updatedCart
//         };
//       } else {
//         return {
//           ...state,
//           cart: [...state.cart, productToAdd]
//         };
//       }

//     case 'UPDATE_CART_ITEM':
//       const updatedProduct = action.payload;
//       const indexProductToUpdate = updatedProduct?.product?._id || updatedProduct?.menu?._id;
//       const indexProduct = (state.selectedItem?.product && state.selectedItem?.product?._id) || (state.selectedItem?.menu && state.selectedItem?.menu?._id);
//       const oldIndex = state.cart.findIndex((item) => {
//         const itemProductID = item.product?._id;
//         const itemMenuID = item.menu?._id;
//         const sameProduct = itemProductID && indexProduct && itemProductID === indexProduct;
//         const sameMenu = itemMenuID && indexProduct && itemMenuID === indexProduct;
//         const sameSize = item?.size === state.selectedItem?.size;
//         const sameSupplements = JSON.stringify(item?.supplements) === JSON.stringify(state.selectedItem?.supplements);
//         const sameIngredientsQts = JSON.stringify(item?.ingredientsQts) === JSON.stringify(state.selectedItem?.ingredientsQts);
//         const sameProducts = JSON.stringify(item?.products) === JSON.stringify(state.selectedItem?.products);

//         if (item.size === "") {
//           return (sameProduct || sameMenu);
//         } else {
//           return (sameProduct || sameMenu) && sameSize && (sameSupplements && sameIngredientsQts) && sameProducts;
//         }
//       });

//       const existingProdIndex = state.cart.findIndex((item) => {
//         const itemProductID = item.product?._id;
//         const itemMenuID = item.menu?._id;
//         const sameProduct = itemProductID && indexProductToUpdate && itemProductID === indexProductToUpdate;
//         const sameMenu = itemMenuID && indexProductToUpdate && itemMenuID === indexProductToUpdate;
//         const sameSize = item?.size === updatedProduct?.size;
//         if (item.size === "") {
//           return (sameProduct || sameMenu);
//         } else {
//           return (sameProduct || sameMenu) && sameSize;
//         }
//       });

//       if (existingProdIndex !== -1) {
//         const updatedCart = [...state.cart];
//         if (updatedCart[oldIndex].quantity > 1) {
//           updatedCart[oldIndex] = updatedProduct;
//           updatedCart[oldIndex].quantity = updatedCart[oldIndex].quantity;
//         }
//         else {
//           if (updatedCart[existingProdIndex]._id == updatedProduct._id) {
//             let test = updatedCart[oldIndex]._id !== updatedProduct._id;
//             if (test) {
//               updatedCart[existingProdIndex].quantity += 1;
//               updatedCart.splice(oldIndex, 1);
//             }
//             else {
//               updatedCart[oldIndex] = updatedProduct;
//             }
//           }

//         }
//         return {
//           ...state,
//           cart: updatedCart
//         };
//       } else {
//         const updatedCart = [...state.cart];
//         updatedCart[oldIndex] = updatedProduct;
//         return {
//           ...state,
//           cart: updatedCart
//         };
//       }

//     case 'REMOVE_CART_ITEM':
//       const updatedCartDel = state.cart.filter(item => item.product._id !== action.payload.productId);
//       return {
//         ...state,
//         cart: updatedCartDel
//       };

//     case "UPDATE_CART_ITEM_QUANTITY":
//       const { productId, quantity } = action.payload;
//       const updatedCart = state.cart.map(item => {
//         if (((item.product && item.product._id) || (item.menu && item.menu._id)) === productId) {
//           return {
//             ...item,
//             quantity
//           };
//         }
//         return item;
//       });
//       return {
//         ...state,
//         cart: updatedCart
//       };
//     case 'UPDATE_CART_ITEM_PRICE':
//       const updatedCommentCart = state.cart.map(item => {
//         if (item.id === action.payload.idItem) {
//           return {
//             ...item,
//             comment: action.payload.comment
//           };
//         }
//         return item;
//       });

//       return {
//         ...state,
//         cart: updatedCommentCart
//       };
//     case 'SELECTED_CART_ITEM':
//       const selectedItem = action.payload;
//       return {
//         ...state,
//         selectedItem: selectedItem,
//       };
//     case 'UPDATE_CART_ITEM_SUCCESS':
//       return {
//         ...state,
//         selectedItem: null,
//       };
//     default:
//       return state;
//   }
// };

// export default cartReducer;

const CartReducer = (state = initState, action) => {

  switch (action.type) {

    case "ADD_TO_CART":

      let item = action.payload
      let productIngredients = item.ingredients
      let productBaseChoices = item.baseChoices.map(item => item.choice)
      let productProductBaseChoices = item.productBaseChoices.map(item => item.choice)
      let productCartItems = state.items.filter(cartItem => cartItem.product._id === item.product._id)
      if (productCartItems.length > 0) {
        let idItem = -1
        productCartItems.map((cartItem, index) => {
          let itemIngredients = JSON.stringify(cartItem.ingredients)
          let itemBaseChoices = cartItem.baseChoices.map(_item => _item.choice)
          let itemProductBaseChoices = cartItem.productBaseChoices.map(_item => _item.choice)
          let isValidItem = true
          productIngredients.map(ingredient => {
            if (itemIngredients.indexOf(JSON.stringify(ingredient)) === -1) { isValidItem = false }
          })
          productBaseChoices.map(baseChoice => {
            if (itemBaseChoices.indexOf(baseChoice) === -1) { isValidItem = false }
          })
          productProductBaseChoices.map(baseChoice => {
            if (itemProductBaseChoices.indexOf(baseChoice) === -1) { isValidItem = false }
          })
          if (isValidItem === true
            && item.product?.name !== "Divers"
            && cartItem.ingredients.length === productIngredients.length
            && itemBaseChoices.length === productBaseChoices.length
            && itemProductBaseChoices.length === productProductBaseChoices.length
            && ((!cartItem.withSizes && !cartItem.product?.isProductWithWeight) || (cartItem.product?.isProductWithWeight && cartItem.size?.name === item.size?.name) || (cartItem.withSizes && cartItem.size?._id === item.size?._id))) {
            idItem = cartItem.id
          }
        })
        if (idItem !== -1) {
          let cartItems = state.items.map((_item, index) => {
            if (_item.id === idItem && state.itemToEdit === null) {
              _item.quantity += item.quantity
            }
            return _item
          })
          return { ...state, items: cartItems, itemToEdit: null }
        } else {
          let _cartItems = state.itemToEdit
            ? getCartItemsUpdated(state.items, item, state.itemToEdit)
            : [...state.items, item]
          return { ...state, items: _cartItems, itemToEdit: null }
        }
      } else {
        let _cartItems = state.itemToEdit
          ? getCartItemsUpdated(state.items, item, state.itemToEdit)
          : [...state.items, item]
        return { ...state, items: _cartItems, itemToEdit: null }
      }

    case "INCREASE_ITEM_QUANTITY":

      if (state.items.findIndex(item => item.id === action.id) !== -1) {
        const items = state.items.reduce((itemsAcc, item) => {
          if (item.id === action.id) {
            itemsAcc.push({
              ...item,
              quantity: item.quantity + 1
            })
          } else {
            itemsAcc.push(item)
          }
          return itemsAcc
        }, [])

        return { ...state, items }
      }
      break;

    case "DECREASE_ITEM_QUANTITY":
      if (state.items.findIndex(item => item.id === action.id) !== -1) {
        const items = state.items.reduce((itemsAcc, item) => {
          if (item.id === action.id && item.quantity > 1) {
            itemsAcc.push({
              ...item,
              quantity: item.quantity - 1,
            })
          } else {
            itemsAcc.push(item)
          }
          return itemsAcc
        }, [])
        return { ...state, items }
      }
      break

    case "UPDATE_CART_ITEMS":
      return { ...state, items: action.items }
    case "SET_ITEM_TO_EDIT":
      return { ...state, itemToEdit: action.item }
    case "RESET_CART":
      return { ...state, items: [] }
    case 'UPDATE_CART_ITEM_PRICE':
      const _items = state.items.map(item => {
        if (item.id === action.data.id) {
          return {
            ...item,
            price: action.data.price || item.price
          };
        }
        return item;
      });
      return { ...state, items: _items };
    default:
      return state;
  }
}

function getCartItemsUpdated(items, newItem, itemToEdit) {
  var cartItems = items.map(item => {
    if (item.id === itemToEdit.id) {
      return newItem
    } else {
      return item
    }
  })
  return cartItems
}

export default CartReducer;