import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faPrint } from '@fortawesome/free-solid-svg-icons'
import InfoCommande from "../componants/modals/OrderDetails";
import { connect, useDispatch, useSelector } from "react-redux";
import { getOrdersAction, setOrderToEdit, setOrderToPrint } from "../store/actions/OrderActions";
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { setTabIndex } from "../store/actions/GlobalActions";
import { LinearProgress } from '@material-ui/core';
import moment from "moment";
import TodayTotalReceipt from "../componants/modals/TodayTotalReceipt";

const Orders = ({ waiter, isWaiter }) => {

    const dispatch = useDispatch();

    const orders = useSelector(state => state.order.userOrders);
    const [search, setSearsh] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("");
    const [isPrintTotalReceipt, setPrintTotalReceipt] = useState(false)

    useEffect(() => {
        dispatch(getOrdersAction(waiter?._id));
    }, []);

    const selectOrder = (order) => {
        dispatch(setOrderToEdit(order));
        dispatch(setTabIndex(1))
    }

    const total = () => {
        let total = 0;
        orders.map((order) => {
            if (order.deliveryMethod === "Sur place" && order.table && !order.paymentMethod && order.status !== "DELIVERED") {
                total += 0
            } else {
                total += order.total
            }
        })
        return total.toFixed(2);
    }

    return (
        <div className="h-[calc(100vh-165px)] w-full flex bg-slate-100 inset-0">
            {/* <Sidebar /> */}

            <div className="w-full" style={{ backgroundColor: "#fafafa" }}>

                <div className="flex flex-row items-center justify-between w-full px-10 py-5">

                    <div className="flex flex-col">
                        {!isWaiter &&
                            <div className="flex items-center gap-1">
                                <h1 className="text-lg font-bold ">AUJOURD'HUI</h1>
                                <FontAwesomeIcon
                                    className="cursor-pointer px-1"
                                    onClick={() => setPrintTotalReceipt(true)}
                                    icon={faPrint}
                                    style={{ color: "#000", height: "22px", width: "22px" }}
                                />
                            </div>
                        }
                        <div className="flex flex-col md:gap-5w-full " style={{ width: '100%' }}>
                            <h1 className="text-lg">Ventes : <b>{orders.length}</b></h1>
                            <h1 className="text-lg">TOTAL: <b>€{total()}</b></h1>
                        </div>
                    </div>
                    <div className="">
                        <FontAwesomeIcon
                            onClick={() => dispatch(getOrdersAction(waiter?._id))}
                            icon={faArrowsRotate}
                            style={{
                                color: "#000",
                                height: "30px",
                                width: "25px"
                            }} />
                    </div>
                </div>

                <div className="flex items-center justify-center py-5 px-10">
                    <div className="w-full">
                        <div className="overflow-y-scroll h-[calc(100vh-390px)]">
                            <table className="table text-black gap-6 text-sm w-full" style={{ borderCollapse: 'separate', backgroundColor: 'white', borderSpacing: '0 7px' }}>
                                <thead className="border-b text-lg">
                                    <tr>
                                        <th className="px-5 py-3 text-left whitespace-nowrap font-medium" style={{ color: "#636D7F" }}>Total</th>
                                        <th className="px-5 py-3 text-left whitespace-nowrap font-medium" style={{ color: "#636D7F" }}>Paiement</th>
                                        <th className="px-5 py-3 text-left whitespace-nowrap font-medium" style={{ color: "#636D7F" }}>Créée à </th>
                                        <th className="px-5 py-3 text-left font-medium" style={{ color: "#636D7F" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.filter((order) => (search === "" ? true : order.mobile?.includes(search)) && (selectedFilter === "" ? true : order.deliveryMethod === selectedFilter)).map((order, i) => {
                                        return (
                                            <tr key={i} class="table-row">
                                                <td className="px-5 text-black text-lg font-medium">
                                                    €{order.total.toFixed(2)}
                                                </td>
                                                <td className="px-5 text-black text-lg font-medium">
                                                    <div style={{ width: 200 }}>
                                                        {order.paymentMethod
                                                            ? order.paymentMethod
                                                            : <i className="font-light text-primary">Non payée</i>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="px-5 text-lg">
                                                    {moment(order.createdAt).format("HH:mm")}
                                                </td>
                                                <td className="">
                                                    <div className="flex gap-3 justify-center">
                                                        <InfoCommande id={order._id} editOrder={() => selectOrder(order._id)} />
                                                    </div>
                                                </td>
                                                <LinearProgress />
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <TodayTotalReceipt
                    orders={orders}
                    isPrintTotalReceipt={isPrintTotalReceipt}
                    setPrintTotalReceipt={(value) => setPrintTotalReceipt(value)}
                />

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        waiter: state.auth.waiter,
        isWaiter: state.auth.isWaiter
    }
}

export default connect(mapStateToProps)(Orders);