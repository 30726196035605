import { React, useEffect, useState, createRef } from 'react'
import QRCode from 'qrcode'
import './style.css';
import logo from '../../assets/images/logo2.png'
import { useScreenshot } from 'use-react-screenshot';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { TOAST } from '../../utils/Helpers';

const TodayTotalReceipt = ({ orders, isPrintTotalReceipt, printerConnexionMode, printers, paperSize, setPrintTotalReceipt }) => {

    useEffect(() => {
        if (isPrintTotalReceipt) {
            printTotalReceipt()
        }
    }, [isPrintTotalReceipt])

    const ref = createRef(null)
    const [image, takeScreenShot] = useScreenshot();

    async function printTotalReceipt() {

        let isUsbPort = printerConnexionMode === "USB"
        let _copies = 1

        if (isUsbPort) {
            let imagedata = await takeScreenShot(ref.current)
            let usbData = (imagedata + "#" + _copies)
            window.location.href = "#print-order-receipt#" + usbData
            setPrintTotalReceipt(false)
        } else {
            if (printers.length === 0) {
                TOAST("Veuillez ajouter une imprimante", "error")
                return
            }
            printers.map(async (printer) => {
                if (printer.ipAddress === "") {
                    TOAST("Veuillez ajouter l'adresse ip de " + printer.name, "error")
                    return;
                }
                let imagedata = await takeScreenShot(ref.current)
                let ethernetData = (imagedata + "#" + _copies + "#" + printer.ipAddress)
                window.location.href = "#print-order-receipt#" + ethernetData + "#" + ref.current.offsetHeight
                setPrintTotalReceipt(false)
            })
        }
    }

    function getCashTotal(isCash) {
        let cashOrders = orders.filter(order => order.paymentMethod && (isCash
            ? (order.paymentMethod === "En espèces")
            : (order.paymentMethod !== "En espèces")))
        let total = 0
        cashOrders.map(item => total += item.total)
        return { count: cashOrders.length, total: total.toFixed(2) }
    }

    function getDaySummary() {
        let total = 0
        let count = orders.filter(order => order.paymentMethod).length
        orders.map(item => total += item.paymentMethod ? item.total : 0)
        return { count: count, total: total.toFixed(2) }
    }

    function getTvaDaySummary(tva) {
        let total = 0
        orders.forEach(order => {
            order.items.forEach(item => {
                item.items.forEach(_item => {
                    if (_item.product.category?.tva === tva) {
                        total += _item.price
                    }
                })

            })
        });
        return total.toFixed(2)
    }

    if (printerConnexionMode === "USB") {
        return <div className='flex flex-col pb-7 items-center' style={{ backgroundColor: 'white', width: paperSize === 58 ? 390 : 570, position: 'absolute', bottom: 5000 }} ref={ref}>
            <p className='text-[30px] font-bold mt-4' style={{ fontWeight: 'bold', color: 'black' }}>{moment(new Date()).format("DD-MM-YYYY")}</p>

            <div className='flex w-full justify-between mt-3 px-4'>
                <p className='text-[25px] mt-3' style={{ fontWeight: 'bold', color: 'black' }}>Chiffre d'affaires</p>
                <p className='text-[25px] mt-3 font-bold' style={{ fontWeight: 'bold', color: 'black' }}>€{getDaySummary().total}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[25px] mt-1' style={{ fontWeight: 'bold', color: 'black' }}>Ventes</p>
                <p className='text-[25px] mt-1 font-bold' style={{ fontWeight: 'bold', color: 'black' }}>{getDaySummary().count}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[25px] mt-3' style={{ fontWeight: 'bold', color: 'black' }}>Chiffre d'affaires <span className='text-[19px]'>TVA 6%</span></p>
                <p className='text-[25px] mt-3 font-bold' style={{ fontWeight: 'bold', color: 'black' }}>€{getTvaDaySummary(6)}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[25px] mt-3' style={{ fontWeight: 'bold', color: 'black' }}>Chiffre d'affaires <span className='text-[19px]'>TVA 21%</span></p>
                <p className='text-[25px] mt-3 font-bold' style={{ fontWeight: 'bold', color: 'black' }}>€{getTvaDaySummary(21)}</p>
            </div>

            <div className='flex w-full justify-between mt-3 px-4'>
                <p className='text-[23px] mt-3' style={{ fontWeight: 'bold', color: 'black' }}>Payée en espèces</p>
                <p className='text-[23px] mt-3 font-bold' style={{ fontWeight: 'bold', color: 'black' }}>€{getCashTotal(true).total}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[23px] mt-1' style={{ fontWeight: 'bold', color: 'black' }}>Ventes</p>
                <p className='text-[23px] mt-1 font-bold' style={{ fontWeight: 'bold', color: 'black' }}>{getCashTotal(true).count}</p>
            </div>

            <div className='flex w-full justify-between mt-3 px-4'>
                <p className='text-[23px] mt-3' style={{ fontWeight: 'bold', color: 'black' }}>Payée en Bancontact</p>
                <p className='text-[23px] mt-3 font-bold' style={{ fontWeight: 'bold', color: 'black' }}>€{getCashTotal(false).total}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[23px] mt-1' style={{ fontWeight: 'bold', color: 'black' }}>Ventes</p>
                <p className='text-[23px] mt-1 font-bold' style={{ fontWeight: 'bold', color: 'black' }}>{getCashTotal(false).count}</p>
            </div>

            <div className='flex my-5 mt-10'>
                <img alt="" src={logo} height={25} width={25} style={{ objectFit: 'contain' }} />
                <p className='text-[25px] font-normal ml-3' style={{ fontWeight: 'bold', color: 'black' }}>Powerd by B-TIP</p>
            </div>
        </div>
    }

    return (
        <div className='flex flex-col pb-7 items-center' style={{ backgroundColor: 'white', width: paperSize === 58 ? 390 : 570, position: 'absolute', bottom: 5000 }} ref={ref}>
            <p className='text-[30px] font-bold mt-4'>{moment(new Date()).format("DD-MM-YYYY")}</p>


            <div className='flex w-full justify-between mt-3 px-4'>
                <p className='text-[23px] mt-3'>Chiffre d'affaires</p>
                <p className='text-[23px] mt-3 font-bold'>€{getDaySummary().total}</p>
            </div>
            <div className='flex w-full justify-between px-4'>
                <p className='text-[23px]'>Ventes</p>
                <p className='text-[23px] mt-1 font-bold'>{getDaySummary().count}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[23px] mt-3'>Chiffre d'affaires <span className='text-[19px]'>TVA 6%</span></p>
                <p className='text-[23px] mt-3 font-bold'>€{getTvaDaySummary(6)}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[23px] mt-3'>Chiffre d'affaires <span className='text-[19px]'>TVA 21%</span></p>
                <p className='text-[23px] mt-3 font-bold'>€{getTvaDaySummary(21)}</p>
            </div>

            <div className='flex w-full justify-between mt-3 px-4'>
                <p className='text-[23px] mt-3'>Payée en espèces</p>
                <p className='text-[23px] mt-3 font-bold'>€{getCashTotal(true).total}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[23px]'>Ventes</p>
                <p className='text-[23px] mt-1 font-bold'>{getCashTotal(true).count}</p>
            </div>

            <div className='flex w-full justify-between mt-3 px-4'>
                <p className='text-[23px] mt-3'>Payée en Bancontact</p>
                <p className='text-[23px] mt-3 font-bold'>€{getCashTotal(false).total}</p>
            </div>

            <div className='flex w-full justify-between px-4'>
                <p className='text-[23px]'>Ventes</p>
                <p className='text-[23px] mt-1 font-bold'>{getCashTotal(false).count}</p>
            </div>

            <div className='flex my-5 mt-10'>
                <img alt="" src={logo} height={25} width={25} style={{ objectFit: 'contain' }} />
                <p className='text-[25px] font-normal ml-3'>Powerd by B-TIP</p>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        receiptVisibleInfos: state.parameters.printerParameters.receiptVisibleInfos,
        printerConnexionMode: state.parameters.printerParameters.printerConnexionMode,
        printerEthernetIpAddress: state.parameters.printerParameters.printerEthernetIpAddress,
        paperSize: state.parameters.printerParameters.receiptPaperSize,
        printers: state.parameters.printerParameters.printers,
        copies: state.parameters.printerParameters.copies,
        orderToPrint: state.order.orderToPrint
    }
}

export default connect(mapStateToProps)(TodayTotalReceipt);