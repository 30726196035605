import { url } from "../../config/BaseUrl";

export const SignInAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: "SIGN_IN",
            email: data.email,
            status: data.status,
            shops: data.shops,
            role: data.role,
            isLoggedIn: true,
            token: data.token
        })
    }
}

export const GET_APP_SHOP = () => {
    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    return (dispatch) => {
        url.get(`shops/${localStorage.getItem("shop_id")}`, { headers: headers })
            .then(res => {
                dispatch({ type: "GET_INFO", shops: res.data })
            })
            .catch(err => { console.log(err); })
    }
}

export const SignOutAction = () => {
    return (dispatch) => { dispatch({ type: "SIGN_OUT" }) }
}

export const SET_IS_WAITER = (value) => {
    return (dispatch) => { dispatch({ type: "SET_IS_WAITER", isWaiter: value }) }
}

export const SET_WAITER = (value) => {
    return (dispatch) => { dispatch({ type: "SET_WAITER", waiter: value }) }
}