import { React, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { url } from "../config/BaseUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.png"
import { TOAST } from "../utils/Helpers";
import { useNavigate } from "react-router-dom";
import { SET_WAITER } from "../store/actions/AuthActions";

const SelectWaiter = ({ rooms, isWaiter }) => {

    useEffect(() => {
        getWaiters()
    }, [])

    const [waiters, setWaiters] = useState([])
    const [selectedWaiter, selectWaiter] = useState(null)
    const [password, setPassword] = useState("")
    const [isShowPassword, setShowPassword] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function getWaiters() {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }

        url.get("waiters/" + localStorage.getItem("shop_id"), { headers: headers })
            .then((response) => {
                setWaiters(response.data)
            })
            .catch((error) => console.log(error))
    }

    function checkPassword(text) {

        if (selectedWaiter === null) {
            TOAST("Sélectionner le serveur", "error")
            return
        }

        let _password = password + text
        setPassword(_password)
        if (_password.length !== 4) {
            return
        }

        if (_password.length === 4 && _password !== selectedWaiter?.password + "") {
            TOAST("Mot de passe incorrect", "error")
            setPassword("")
            return
        }

        if (_password.length === 4 && _password === selectedWaiter?.password + "") {
            dispatch(SET_WAITER(selectedWaiter))
            navigate("/home")
            return
        }

    }

    return (
        <div className="flex flex-col w-screen h-screen p-[100px]" style={{ backgroundColor: "#fff" }}>

            <img style={{ objectFit: 'contain', position: 'absolute', height: 150, width: 150, top: 20, alignSelf: 'center' }} src={logo} alt="" />

            <div className="flex mt-[100px] shadow-xl" style={{ borderRadius: 5, overflow: 'hidden' }}>
                <div className="flex flex-col h-[480px] flex-1 border-1 primary overflow-y-scroll" style={{ backgroundColor: '#cacaca' }}>
                    {waiters.map((waiter) => (
                        <div
                            className={"h-[80px] items-center flex p-5 " + (selectedWaiter === waiter ? "bg-primary text-white" : "bg-gray text-black")}
                            style={{ borderBottomWidth: 2, borderColor: '#000' }}
                            onClick={() => { selectWaiter(waiter); setPassword("") }}
                        >
                            <p className="text-[25px] font-medium">{waiter.name}</p>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col  h-[480px] flex-1 bg-black">
                    <div className="flex bg-black h-[120px]">
                        <input
                            style={{ pointerEvents: 'none', backgroundColor: 'transparent' }}
                            className={"text-[40px] text-center text-white text-white w-[100%]"} type={isShowPassword ? "text" : "password"} max={4} maxLength={4} value={password} />
                        <FontAwesomeIcon className={"flex-1 relative top-10 right-5"} onClick={() => setShowPassword(!isShowPassword)} color="#fff" style={{ height: 30, width: 30 }} icon={isShowPassword ? faEyeSlash : faEye} />
                    </div>
                    <div className="flex bg-black h-[120px]">
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(7)}>7</button>
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(8)}>8</button>
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(9)}>9</button>
                    </div>
                    <div className="flex bg-black h-[120px]">
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(4)}>4</button>
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(5)}>5</button>
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(6)}>6</button>
                    </div>
                    <div className="flex bg-black h-[120px]">
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(1)}>1</button>
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(2)}>2</button>
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => checkPassword(3)}>3</button>
                    </div>
                    <div className="flex bg-black h-[120px]">
                        <button className={"flex-1 text-[40px] text-white"}></button>
                        <button className={"flex-1 text-[40px] text-white"} onClick={() => { setPassword(password + "0"); checkPassword(0) }}>0</button>
                        <div className="flex flex-1 items-center justify-center">
                            <FontAwesomeIcon className={"flex-1 "} onClick={() => setPassword(password.slice(0, -1))} color="#fff" style={{ height: 40, width: 40 }} icon={faDeleteLeft} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        rooms: state.rooms.rooms,
        isWaiter: state.auth.isWaiter
    }
}

export default connect(mapStateToProps)(SelectWaiter);