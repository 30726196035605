import React, {  } from 'react'
import { useDispatch, connect } from 'react-redux';
import { updateCartItems } from '../../store/actions/CartActions'
import CartItem2 from './CartItem2'
import { CART_TOTAL } from '../../utils/Helpers'

const Cart2 = ({ setShowpanier, orderToEdit, deliveryMethod, updateBarCodeFocus, mobile, selectedAddress, setShowPayModal, table, resetTable, coupon, resetAllStates, cart, waiter, hideModal, isShowCartModal }) => {

  const dispatch = useDispatch();

  return (
    <div className={'flex flex-col items-center bg-white w-full h-full'}>
      <div className='w-full h-full flex-1 px-3 mt-5 overflow-y-scroll scrollbar-none'>
        {cart.map((item) => {
          return (
            <CartItem2
              _item={item}
              removeCartItem={(id) => dispatch(updateCartItems(cart.filter(cartItem => cartItem.id !== id)))}
              updateBarCodeFocus={(value) => updateBarCodeFocus(value)}
            />
          )
        })}
      </div>
      <div className='flex justify-between p-4 bg-gray-200 w-full'>
        <span className='font-bold text-4xl'>
          TOTAL
        </span>
        <span className='font-bold text-4xl'>
          €{CART_TOTAL(cart, 0, null).toFixed(2)}
        </span>
      </div>
    </div >
  )
}

function mapStateToProps(state) {
  return {
    coupon: state.order.coupon,
    orderToEdit: state.order.orderToEdit,
    cart: state.cart.items,
    waiter: state.auth.waiter
  }
}

export default connect(mapStateToProps)(Cart2);