import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { addToCart, setItemToEdit } from '../../store/actions/CartActions';
import SelectComponent from './SelectComponent';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
// import { updateCartItem } from '../../store/actions/CartActions'

const MenuInfo = ({ menu, category, selectMenu, selectedMenu, setCategory }) => {

  const categories = useSelector((state) => state.category.categories);
  const itemToEdit = useSelector(state => state.cart.itemToEdit);
  const [show, setShow] = useState(false);
  const [selectedBaseChoices, setSelectedBaseChoices] = useState([])
  const dispatch = useDispatch();

  function getFristCategoryProductName(idCategory) {
    let category = categories.filter(item => item._id === idCategory)[0]
    return category.products[0]?.name
  }

  const handleChoiceSelect = (name, idBaseChoice) => {
    var choices = selectedBaseChoices.map(selectedBaseChoice => {
      if (selectedBaseChoice.id === idBaseChoice) {
        selectedBaseChoice.choice = name
        selectedBaseChoice.price = 0
      }
      return selectedBaseChoice
    })
    setSelectedBaseChoices(choices)
  };

  const handleSelectMenu = () => {
    selectMenu(menu)
    setShow(true);
    let choices = menu.choices.map(item => ({
      id: item._id,
      name: item.name,
      price: 0,
      choice: (item.all_category_products === true ? getFristCategoryProductName(item.category) : item.products[0]?.name)
    }))
    setSelectedBaseChoices(choices)
  };

  useEffect(() => {
    if (itemToEdit?.product?._id === menu._id) {
      selectMenu(menu)
      setSelectedBaseChoices(itemToEdit.baseChoices)
      setShow(true);
    }
  }, [itemToEdit]);

  const handleAddToCart = () => {

    let refCartItem = new Date().getTime()
    let item = {
      id: refCartItem,
      category: category,
      price: menu.price,
      quantity: 1,
      product: menu,
      ingredients: [],
      baseChoices: selectedBaseChoices,
      productBaseChoices: [],
      comment: "",
      withSizes: false,
      isMenuItem: true
    }

    dispatch(addToCart(item))
    setCategory(null)
    dispatch(setItemToEdit(null))
    setShow(false)
    resetStates()
  };

  function getCategoryProducts(idCategory) {
    let category = categories.filter(item => item._id === idCategory)[0]
    return category ? category.products : []
  }

  function resetStates() {

    // eslint-disable-next-line no-unused-expressions
    !itemToEdit ? selectMenu(null) : null
    setSelectedBaseChoices([])
  }


  return (
    <>
      <div
        style={{ pointerEvents: menu?.isAvailable === false ? 'none' : null }}
        onClick={() => handleSelectMenu()}
        className={`shadow-lg border text-center cursor-pointer h-[142px] justify-center items-center flex flex-col ${selectedMenu?._id === menu._id ? 'bg-primary text-white' : 'bg-white text-black'}`}>
        <div className="font-sans font-bold text-[22px]" style={{ lineHeight: 1, opacity: menu?.isAvailable === false ? 0.4 : 1 }}>{menu.name}</div>
        <div className="font-sans text-lg font-semibold py-2 text-[20px]" style={{  opacity: menu?.isAvailable === false ? 0.4 : 1 }}>{menu.choices.length} Choix</div>
        <div className="font-sans font-bold text-xl" style={{ opacity: menu?.isAvailable === false ? 0.4 : 1 }}>€{menu.price}</div>
        {menu?.isAvailable === false && <div className="font-sans font-bold text-lg mt-2 text-primary absolute mt-[115px]" style={{ opacity: 1, color: 'red' }}>Indisponible</div>}
      </div>

      <Dialog
        open={show}
        maxWidth='xl'
        fullWidth
        onClose={() => { setShow(false); resetStates() }}
        className="modalWrapper">
        <DialogTitle>

          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <div>
              <h3 className="text-2xl font-semibold">{selectedMenu?.name}</h3>
              <span className="text-gray-600 text-xl">
                {selectedMenu?.choices.length} Choix
              </span>
            </div>
            <button
              className="p-1 bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => { setShow(false); resetStates() }}
            >
              <FontAwesomeIcon icon={faClose} style={{ color: "#000", height: 30, width: 30 }} />
            </button>
          </div>

        </DialogTitle>

        <DialogContent>

          <div className="relative flex-col md:flex-row md:flex-auto ">
            <div className='w-full sm:w-full '>
              {selectedMenu?.choices.map((choice, index) => {
                return (
                  <div className="flex flex-col my-10 px-10 justify-center" key={choice._id}>
                    <p className="text-xl mb-2 font-normal">{choice.name} </p>
                    <SelectComponent
                      data={(choice.all_category_products === true
                        ? getCategoryProducts(choice.category)
                        : choice.products).map((product, index) => ({ value: product.name, label: product.name }))
                      }
                      selectItem={(value) => handleChoiceSelect(value, choice._id)}
                      value={{ value: selectedBaseChoices[index]?.choice, label: selectedBaseChoices[index]?.choice }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

        </DialogContent>

        <DialogActions>
          <div className="flex w-full border-t ">
            <button
              className="bg-black flex gap-3 w-full items-center justify-center text-white font-bold uppercase text-xl py-12"
              type="button"
              onClick={() => { setShow(false); resetStates() }}>
              <FontAwesomeIcon icon={faClose} style={{ color: "#fff", height: 25, width: 25 }} />
              FERMER
            </button>
            <button
              className="bg-primary flex gap-3 w-full items-center justify-center text-white font-medium uppercase text-xl py-12"
              type="button"
              onClick={() => handleAddToCart()}>
              <FontAwesomeIcon icon={faCartPlus} style={{ color: "#fff", height: 25, width: 25 }} />
              {itemToEdit !== null ? ' Modifier' : 'Ajouter au panier'} - <b>€23</b>
            </button>

          </div>
        </DialogActions>

      </Dialog>
      {/* 

      {show ? (
        <>
          <div className="justify-center items-end flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full mx-auto w-full h-[95%]">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none min-h-full">

               

              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null} */}
    </>
  );
};

export default MenuInfo;
