import React from 'react';
import Select from 'react-select/creatable';

const SelectComponent = ({ data, selectItem, value, style, addNewCategory }) => {

    const colourStyles = {
        control: styles => ({ ...styles, height: 70, fontSize: 20, fontWeight: 'bold'}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: "#fafafa",
                fontSize: 20,
                fontWeight: 'bold',
                color: isSelected ? 'red' : 'black',
                height: 60
          }
        },
      };


      const handleChange = (newValue, actionMeta) => {
        if(newValue.__isNew__){

            // Check category if already exists
            // addNewCategory(newValue.label)
            selectItem({name: newValue.label, isNew: true})

        }else{
            selectItem(newValue.value)
        }
      };

    return (
        <Select
            isClearable={true}
            isSearchable={true}
            name="color"
            options={data}
            styles={style || colourStyles}
            placeholder="Sélectionner une catégorie"
            onChange={handleChange}
            value={value}
            formatCreateLabel={(value) => `Créer ${value}`}
        />
    );
};

export default SelectComponent