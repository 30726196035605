import { url } from "../../config/BaseUrl";

export const GetSearchAction = () => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    return (dispatch, getState) => {
        url.post("addresses/search", {
            "shop": "60dcb118128d6847670a958a",
            "mobile": "0657852301"
        }, {
            headers: headers
        })
            .then(response => {
                if (response.status === 200) {

                    dispatch({
                        type: "GET_LOCALISATIONS",
                        paylod: {
                            searsh: response.data
                        }
                    });
                }
                else {
                    dispatch({
                        type: "GET_LOCALISATIONS",
                        paylod: {
                            searsh: []
                        }
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: "GET_LOCALISATIONS",
                    paylod: {
                        searsh: []
                    }
                });
            });
    }
}