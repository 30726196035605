/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import moment from 'moment';
import { setOrderToEdit, createOrder, updateOrder, setCoupon } from '../../store/actions/OrderActions';
import { faCircleXmark, faClose, faTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { resetCart } from '../../store/actions/CartActions';
import cash from '../../assets/images/cash.png'
import bancontact from '../../assets/images/bancontact.png'
import { CART_SUBTOTAL, CART_TOTAL, GET_CART_ITEM_TOTAL, SUBTOTAL_COUPON, TOAST } from '../../utils/Helpers';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { setTabIndex } from '../../store/actions/GlobalActions';
import { url } from '../../config/BaseUrl';
import OrderReceipt from './OrderReceipt';

const CreateOrder = ({ resetAllStates, cart, waiter, orderToPrint, setShowPayModal, deliveryPrice, orderToEdit, table, resetTable, coupon }) => {

  const [show, setShow] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [comment, setComment] = useState("");
  const [isFastestDelivery, setIsFastestDelivery] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [notShow, setNotShow] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const dispatch = useDispatch();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const currentDate = moment();
  const [isPercent, setPercent] = useState(null)
  const [discountValue, setDiscountValue] = useState("")
  const [isApplyDiscount, setApplyDiscount] = useState(false)

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setIsFastestDelivery(true);
      setSelectedTime(null);
    } else {
      setIsFastestDelivery(false);
      setSelectedDuration(null);
    }
  };

  const handleCheckboxChange2 = (event) => {
    if (event.target.checked) {
      setIsFastestDelivery(false);
      setSelectedDuration(null);
    } else {
      setIsFastestDelivery(true);
      setSelectedTime(null);
    }
  };

  async function saveOrder() {

    if (cart.length === 0) {
      TOAST('Veuillez ajouter des produits à votre panier.', "error")
      return
    }

    if (selectedPaymentMethod === null && table === null) {
      TOAST('Veuillez choisir un mode de paiement.', "error")
      return
    }

    const orderData = {
      deliveryMethod: "inplace",
      items: cart.map(item => ({
        product: item.isMenuItem ? null : item.product._id,
        menu: item.isMenuItem ? item.product._id : null,
        quantity: item.quantity,
        description: item.comment,
        size: item.size === null ? null : item.size?.name + (item.product.isProductWithWeight ? " Kg" : ""),
        price: GET_CART_ITEM_TOTAL(item),
        ingredients: item.ingredients.map(ingredient => ingredient._id),
        ingredientsQts: item.ingredients.map(ingredient => ({ _id: ingredient._id, quantity: ingredient.quantity })),
        baseChoices: item.baseChoices,
        productBaseChoices: item.productBaseChoices
      })),
      total: getTotalDiscountPrice(),
      subtotal: CART_SUBTOTAL(cart),
      paymentMethod: selectedPaymentMethod,
      comment: isPercent !== null && discountValue !== 0 ? getDiscountPrice() : "",
      coupon: coupon,
      shop: localStorage.getItem("shop_id"),
      isPosOrder: true
    };

    console.log("orderData", orderData)

    orderToEdit
      ? dispatch(updateOrder(orderData, orderToEdit._id, waiter?._id))
      : dispatch(createOrder(orderData, waiter?._id))
    resetTable()
    resetStates()
    setShow(false);
    setShowPayModal(false);
    dispatch(setOrderToEdit(null))
    resetAllStates()
    dispatch(setCoupon(null))
    dispatch(resetCart())
  }

  useEffect(() => {
    if (orderToEdit) {
      setComment(orderToEdit.comment);
      setSelectedPaymentMethod(orderToEdit.paymentMethod);
    }
  }, [orderToEdit])

  function clearCart() {
    dispatch(resetCart())
    dispatch(setCoupon(null))
  }

  function getSupplementsText(item) {
    let text = ""
    item.ingredients?.map((supplement, index) =>
      text += (
        (supplement.quantity
          ? ((supplement.quantity > 1 ? (supplement.quantity + "x ") : "") + supplement.name)
          : supplement.name
        ) + ", "))

    item.baseChoices.map((item, index) => text += item.choice + ", ")
    item.productBaseChoices.map((item, index) => text += item.choice + ", ")

    return text.substring(0, text.length - 2)
  }

  function resetStates() {

    setSelectedDuration(null)
    setComment("")
    setIsFastestDelivery(null)
    setSelectedTime(null)
    setSelectedPaymentMethod(null)
    setPercent(null)
    setDiscountValue("")
    setNotShow(true)
  }

  function getDiscountPrice() {
    
    let _discountValue = discountValue === "" ? 0 : discountValue
    let total = CART_TOTAL(cart, deliveryPrice, coupon).toFixed(2)
    let discountPrice = (isPercent ? (total * parseFloat(_discountValue) * 0.01) : parseFloat(_discountValue)).toFixed(2)
    return discountPrice < 0 || discountPrice === "NaN" ? 0 : discountPrice
  }


  function getTotalDiscountPrice() {
    let subtotal = CART_SUBTOTAL(cart).toFixed(2)
    let discountPrice = getDiscountPrice()
    let total = subtotal - discountPrice
    return total < 0 ? 0 : total.toFixed(2)
  }

  return (
    <>

      {/* <div className='flex h-[70px] justify-between border-2 border-gray-700 border-b-0'>
        <input
          className='text-xl w-full px-2'
          style={{ transition: 'all .2s' }}
          placeholder="Entrer le code coupon"
          value={couponCode}
          onChange={(event) => setCouponCode(event.target.value)}
        />
        <button disabled={couponCode === ""} onClick={() => validateCoupon()} style={{ backgroundColor: "#2B66FF", color: "#fff", transition: 'all .2s' }} className='flex flex-row  w-[250px] justify-center gap-2 py-3 items-center bg-white w-full text-primary text-xl uppercase'>
          <FontAwesomeIcon className='cursor-pointer' icon={faTag} style={{ color: "#FFF", height: "20px", width: "20px" }} />
          {"Vérifier".toUpperCase()}
        </button>
      </div> */}

      <button onClick={() => clearCart()} style={{ border: "2px solid #F01D2C", color: "#F01D2C" }} className='flex flex-row justify-center gap-2 items-center bg-white w-full py-3 text-primary text-sm uppercase'>
        <FontAwesomeIcon className='cursor-pointer' icon={faCircleXmark} style={{ color: "#F01D2C", height: "20px", width: "20px" }} />
        VIDER LE PANIER
      </button>

      <button onClick={() => {setShow(true); setShowPayModal(true);}} style={{ opacity: cart.length === 0 ? 0.5 : 1, pointerEvents: cart.length === 0 ? 'none' : null, backgroundColor: '#02C34F' }} className='py-5 w-full font-semibold text-white text-[18px] uppercase'>
        Payer (€{CART_TOTAL(cart, deliveryPrice, coupon).toFixed(2)})
      </button>

      {orderToPrint &&
        <OrderReceipt backToHome={() => null} />
      }
      <Dialog
        open={show}
        maxWidth='xl'
        fullWidth
        PaperProps={{
          style: {
            minHeight: '90%'
          }
        }}
        onClose={() => { setShow(false); setShowPayModal(false); resetStates() }}
        className="modalWrapper">

        {/* <DialogTitle>
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 className="text-2xl font-semibold">Commander</h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => { setShow(false); resetStates() }}>
              <FontAwesomeIcon icon={faClose} style={{ color: "#000", height: "30px", width: "30px" }} />
            </button>
          </div>
        </DialogTitle> */}

        <DialogContent>
          <div className="relative flex-auto ">
            <div className=' flex-col md:flex-row gap-5'>
              <div className='w-full'>
                {/* {!waiter &&
                  <>
                    <h3 className='text-xl font-medium text-start'>Temps de livraison : <span className='font-bold text-[25px]'> <br />{orderToEdit?.delivery_time}</span></h3>
                    <div className='justify-start flex'>
                      {orderToEdit?.delivery_time &&
                        <button
                          onClick={() => setNotShow(!notShow)}
                          type='button'
                          className='bg-primary w-[200px] text-xl mt-2 rounded-md p-3 text-white'>
                          {!notShow && <FontAwesomeIcon className='cursor-pointer' icon={faClose} style={{ color: "#FFF", height: "20px", width: "20px", marginRight: 5 }} />}
                          {notShow ? "Modifier" : "Annuler"}
                        </button>
                      }
                    </div>
                    <div className={orderToEdit?.delivery_time && notShow ? 'hidden' : 'block border-2 mt-4 shadow-lg py-4'}>
                      <div className="mb-[0.125rem] flex gap-3 items-center min-h-[1.5rem] pl-[1.5rem] mt-5">
                        <input
                          type="checkbox"
                          checked={isFastestDelivery === true}
                          onChange={handleCheckboxChange}
                          id="checkboxDefault"
                          style={{ height: 25, width: 25 }}
                        />
                        <label
                          className="inline-block pl-[0.15rem] hover:cursor-pointer font-normal text-xl"
                          htmlFor="checkboxDefault">
                          Le plus vite possible
                        </label>
                      </div>
                      <div className='grid grid-cols-3  2xl:flex p-4 mt-2 rounded-lg'>
                        {[10, 20, 30, 40, 50, 60, 70, 80, 90].map((duration) => (
                          <button
                            disabled={isFastestDelivery === null || isFastestDelivery === false}
                            type='button'
                            key={duration}
                            className={`border px-4 shadow-md py-5 text-xl ${selectedDuration === duration ? 'bg-primary text-white' : ' bg-white border-black text-black'}`}
                            style={{ opacity: isFastestDelivery === true ? 1 : 0.2 }}
                            onClick={() => setSelectedDuration(duration)}
                          >
                            {duration} min
                          </button>
                        ))}
                      </div>
                      <div className="mb-[0.125rem] flex gap-3 items-center min-h-[1.5rem] pl-[1.5rem] mt-5">
                        <input
                          type="checkbox"
                          checked={isFastestDelivery === false}
                          onChange={handleCheckboxChange2}
                          id="checkboxDefault"
                          style={{ height: 25, width: 25 }}
                        />
                        <label
                          className="inline-block pl-[0.15rem] hover:cursor-pointer font-normal text-xl"
                          htmlFor="checkboxDefault">
                          Temps spécifique
                        </label>
                      </div>

                      <div className='mt-4 px-5 py-2'>
                        <input
                          disabled={isFastestDelivery === null || isFastestDelivery === true}
                          id="deliveryTimeInput"
                          name="deliveryTime"
                          className="w-full h-20 rounded-md text-xl border-2 px-3 shadow-lg"
                          type="time"
                          value={selectedTime || ''}
                          onChange={(e) => setSelectedTime(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                } */}

                <div className='flex justify-center'>
                  <button
                    type='button'
                    className={`border-2 border-gray-500 py-4 gap-3 flex items-center justify-center w-full px-[25px] text-[19px] font-medium ${selectedPaymentMethod === 'En espèces'
                      ? "bg-primary text-white"
                      : 'border-black bg-white text-black'
                      }`}
                    onClick={() => setSelectedPaymentMethod('En espèces')}
                    style={{ borderWidth: 1 }}
                  >
                    <img alt="" src={cash} width={30} height={30} style={{ objectFit: 'contain' }} />
                    En Espèces
                  </button>
                  <button
                    type='button'
                    className={`border-2 border-gray-500 py-4 gap-3 flex items-center justify-center w-full px-[25px] text-[19px] font-medium ${selectedPaymentMethod === 'Bancontact'
                      ? "bg-primary text-white"
                      : 'border-black bg-white text-black'
                      }`}
                    style={{ borderWidth: 1 }}
                    onClick={() => setSelectedPaymentMethod('Bancontact')}
                  >
                    <img alt="" src={bancontact} width={40} height={40} style={{ objectFit: 'contain' }} />
                    BanContact
                  </button>
                </div>

                {/* <h3 className={'text-xl font-medium text-start mb-1'}>Remarque :</h3>
                <textarea
                  className="mt-5 peer block font-semibold text-xl shadow-lg border w-full rounded bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear"
                  rows={5}
                  placeholder="....."
                  type='text'
                  multiple
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                /> */}

              </div>
              <div className='overflow-y-auto mt-5'>
                {cart.map((item) => {
                  return (
                    <div className='bg-white w-full text-start shadow-lg rounded-md py-4 px-4 border border-gray-200 mb-2'>
                      <div className='flex justify-between'>
                        <div className='flex gap-3'>
                          <span className='text-base text-lg font-bold'>{item.quantity}</span>
                          <h4 className='text-base text-lg font-bold'>{item.menu?.name || item.product?.name}<span className='font-normal text-[16px]'>{item.size && (" - " + (item.size?.name || item.size) + (item.product?.isProductWithWeight ? " Kg" : ""))}</span></h4>
                        </div>
                        <span className='font-bold pt-1 text-lg'>€{GET_CART_ITEM_TOTAL(item).toFixed(2)}</span>
                      </div>
                      <div className='flex '>
                        <p className='text-gray-500'>{getSupplementsText(item)}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </DialogContent>



        <div className='flex justify-between border-y-2 py-2 ml-5 mr-5'>
          <span className='font-medium text-[20px]'>Sous Total</span>
          <span className='font-medium text-[20px]'>€{CART_SUBTOTAL(cart).toFixed(2)}</span>
        </div>

        {/* <div className='flex justify-between items-center gap-5 border-y-2 py-2 ml-5 mr-5'>
          <span className='font-medium text-[20px]'>Réduction</span>
          <div className='flex items-center gap-2 '>
            <input
              type="checkbox"
              className='h-[24px] w-[24px]'
              checked={isPercent === true}
              onChange={(e) => setPercent(true)}
            />
            <span className='font-medium text-lg text-center'>%</span>
            <input
              type="checkbox"
              className='h-[24px] w-[24px]'
              checked={isPercent === false}
              onChange={(e) => setPercent(false)}
            />
            <span className='font-medium text-lg text-center'>€</span>

            <input
              type="number"
              min={0}
              style={{ pointerEvents: isPercent === null ? 'none' : null, opacity: isPercent === null ? 0.3 : 1 }}
              className="form-control h-10 rounded ml-5 border border-gray-700 px-4 text-md bg-white"
              placeholder="..."
              value={discountValue}
              onChange={(e) => setDiscountValue(e.target.value)}
            />

          </div>
          <span className='font-medium text-[20px]'>-€{getDiscountPrice()}</span>
        </div> */}

        <div className='flex justify-between border-y-2 py-4 ml-5 mr-5'>
          <span className='font-bold text-[22px]'>TOTAL</span>
          <span className='font-bold text-[22px]'>€{getTotalDiscountPrice()}</span>
        </div>

        <div className="flex w-full">
          <button
            className="bg-black w-[35%] w-full text-white font-bold uppercase text-[20px] py-5"
            onClick={() => {setShow(false); setShowPayModal(false);}}>
            FERMER
          </button>
          <button
            className="bg-primary w-full text-white font-bold uppercase text-[20px] py-5"
            onClick={() => saveOrder()}>
            Payer (€{getTotalDiscountPrice()})
          </button>
        </div>
      </Dialog>
    </>
  )
}

function mapStateToProps(state) {
  return {
    coupon: state.order.coupon,
    orderToEdit: state.order.orderToEdit,
    cart: state.cart.items,
    waiter: state.auth.waiter,
    orderToPrint: state.order.orderToPrint
  }
}

export default connect(mapStateToProps)(CreateOrder);