const initState = {
    rooms: [],

}
const RoomsReducer = (state = initState, action) => {

    switch (action.type) {
        case "SET_ROOMS":
            return {
                ...state,
                rooms: action.rooms,
            }
        default:
            return state;
    }

}

export default RoomsReducer;