import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer.js";
import OrderReducer from "./OrderReducer.js";
import CategoriesReducer from "./CategoriesReducer.js";
import SearchReducer from "./SearchReducer.js";
import CartReducer from "./CartReducer.js";
import ParametersReducer from "./ParametersReducer.js";
import GlobalReducer from "./GlobalReducer.js";
import RoomsReducer from "./RoomsReducer.js";

const RootReducer = combineReducers({
    auth: AuthReducer,
    order: OrderReducer,
    category: CategoriesReducer,
    address: SearchReducer,
    cart: CartReducer,
    parameters: ParametersReducer,
    global: GlobalReducer,
    rooms: RoomsReducer
});

export default RootReducer;