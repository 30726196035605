import { url } from "../../config/BaseUrl";

export const GetUserCategoriesAction = () => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    return (dispatch, getState) => {
        url.get(`categories/${localStorage.getItem("shop_id")}`, {
            headers: headers
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: "GET_USER_CATEGORIES",
                        paylod: {
                            categories: response.data
                        }
                    });
                }
                else {
                    dispatch({
                        type: "GET_USER_CATEGORIES",
                        paylod: {
                            categories: []
                        }
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: "GET_USER_CATEGORIES",
                    paylod: {
                        categories: []
                    }
                });
            });
    }
}

export const GetUserMenussAction = () => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    return (dispatch, getState) => {
        url.get(`menus/${localStorage.getItem("shop_id")}`, {
            headers: headers
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: "GET_USER_MENUS",
                        paylod: {
                            menus: response.data
                        }
                    });
                }
                else {
                    dispatch({
                        type: "GET_USER_MENUS",
                        paylod: {
                            menus: []
                        }
                    });

                }
            })
            .catch(err => {
                dispatch({
                    type: "GET_USER_MENUS",
                    paylod: {
                        menus: []
                    }
                });
            });
    }
}

export const GetSuplements = () => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
    
    return (dispatch, getState) => {
        url.get(`ingredients/${localStorage.getItem("shop_id")}`, {
            headers: headers
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: "GET_SUPPLEMENTS",
                        paylod: {
                            supplements: response.data
                        }
                    });
                }
                else {

                    dispatch({
                        type: "GET_SUPPLEMENTS",
                        paylod: {
                            supplements: []
                        }
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: "GET_SUPPLEMENTS",
                    paylod: {
                        supplements: []
                    }
                });
            });
    }
}