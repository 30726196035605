import { url } from "../../config/BaseUrl";
import { updateCartItems } from "./CartActions";

export const getOrdersAction = (waiter) => {

    return async (dispatch, getState) => {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }

        await url.get(`orders/shop/${localStorage.getItem("shop_id")}/${waiter}`, { headers: headers })
            .then(response => {
                console.log(response.data)
                dispatch({ type: "GET_USER_ORDERS", payload: { orders: response.data } });
            })
            .catch(err => {
            });
    }
}

export const GetOrderInfoAction = (order_id) => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    return (dispatch, getState) => {
        url.get(`orders/${order_id}`, { headers: headers })
            .then(response => {
                if (response.status === 200) {
                    dispatch({ type: "GET_ORDER_INFO", payload: { order_info: response.data } });
                }
            })
            .catch(err => console.log(err));
    }
}

export const createOrder = (orderData, waiter) => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
    return async (dispatch, getState) => {
        const data = JSON.stringify(orderData);
        await url.post(`orders`, data, { headers: headers })
            .then(async response => {
                dispatch({ type: "CREATE_ORDER", payload: response.data });
                dispatch({ type: "SET_ORDER_TO_PRINT", order: { ...response.data, isKitchenInPlaceReceipt: response.data.table ? true : false } });
                dispatch({ type: "SET_TAB_INDEX", index: 0 });
                getOrdersAction(waiter)
            })
            .catch(err => {
                console.log("Error Add Order")
                console.log(err)
            });
    }
};

export const updateOrder = (orderData, order_id, waiter) => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    return (dispatch, getState) => {
        const data = JSON.stringify(orderData);
        url.put(`orders/${order_id}`, data, { headers: headers })
            .then(response => {
                dispatch({ type: "SET_TAB_INDEX", index: 0 });
                getOrdersAction(waiter)
            })
            .catch(err => { console.log(err); });
    }
};

export const setOrderToEdit = (order_id) => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    return (dispatch, getState) => {

        url.get(`orders/${order_id}`, { headers: headers })
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: "SELECTED_ORDER",
                        payload: { orderToEdit: response.data }
                    });
                    let items = []
                    response.data.items.forEach((cartItem, index) => {

                        cartItem.items.forEach((item, index) => {

                            let ref = item._id
                            let isMenuItem = item.menu !== null
                            let itemSize = item.product?.category?.sizes.filter(_item => _item.name === item.size)[0]
                            let itemPrice = isMenuItem
                                ? item.menu?.price
                                : (itemSize
                                    ? item.product.prices.filter(price => price.size?._id === itemSize?._id)[0].price
                                    : item.product.prices[0].price)
                            if (itemSize) {
                                itemSize = { ...itemSize, price: itemPrice }
                            }
                            let data = {
                                id: ref,
                                category: isMenuItem ? { name: "Menus", products: item.menu } : item.product?.category,
                                price: itemPrice,
                                size: itemSize,
                                quantity: item.quantity,
                                product: isMenuItem ? item.menu : item.product,
                                ingredients: isMenuItem
                                    ? []
                                    : item.ingredients.map((ingredient, index) => ({
                                        ...ingredient,
                                        quantity: item.ingredientsQts[index].quantity
                                    })),
                                baseChoices: item.baseChoices,
                                productBaseChoices: item.productBaseChoices,
                                comment: item.comment,
                                withSizes: isMenuItem ? false : (item.size !== null),
                                isMenuItem: isMenuItem
                            }
                            items.push(data)
                        })
                        if (response.data.items.length - 1 === index) {
                            dispatch(updateCartItems(items));
                        }
                    });
                }
            })
            .catch(err => console.log(err.response));
    }
}

export const resetOrderToPrint = () => {
    return {
        type: 'SELECTED_ORDER',
        payload: { orderToEdit: null }
    };
};

export const setOrderToPrint = (order) => {
    return {
        type: 'SET_ORDER_TO_PRINT',
        order
    };
};

export const setCoupon = (coupon) => {
    return {
        type: 'SET_COUPON',
        coupon
    };
};