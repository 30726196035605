export const addToCart = (product) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_TO_CART',
      payload: product
    })
  };
};

export const increaseItemQuantity = (id) => {
  return {
    type: 'INCREASE_ITEM_QUANTITY',
    id
  };
};

export const decreaseItemQuantity = (id) => {
  return {
    type: 'DECREASE_ITEM_QUANTITY',
    id
  };
};

export const updateCartItems = (items) => {
  return {
    type: 'UPDATE_CART_ITEMS',
    items
  };
};

export const updateCartItemPrice = (data) => {
  return {
    type: 'UPDATE_CART_ITEM_PRICE',
    data
  };
};

export const setItemToEdit = (item) => {
  return {
    type: 'SET_ITEM_TO_EDIT',
    item
  };
};

export const resetCart = () => {
  return { type: 'RESET_CART' };
};