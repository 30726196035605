import { React } from "react";
import logo from "../assets/images/delices-de-maria.png"
import minLogo from "../assets/images/mini-logo.png"
import { connect } from "react-redux";
import Cart from "../componants/globals/CartResume";

const CartResume = ({ scaleWeight }) => {

    return (
        <div className="h-[100vh] w-full flex flex-row justify-between">
            <div className="bg-white" style={{ backgroundColor: '#0F709E', display: 'flex', flexDirection: 'column', width: '40%', justifyContent: 'space-between' }}>
                <img className="h-[30%] w-[100%] mt-10" style={{ objectFit: 'contain' }} src={logo} alt="" />
                <div>
                    <p style={styles.weightUnit}>kg</p>
                    <p style={styles.weightValueLabel}>{scaleWeight}</p>
                </div>
                <div />
                <div className="flex items-center jusifty-center" style={{ alignSelf: 'center'}}>
                    <img className="h-[40px] w-[40px] mb-5 mr-2" style={{ objectFit: 'cover' }} src={minLogo} alt="" />
                    <p style={styles.powered}>Powerd by B-TIP</p>
                </div>
            </div>
            <div style={{ backgroundColor: 'red', display: 'flex', width: '60%' }}>
                <Cart />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        scaleWeight: state.global.scaleWeight
    }
}

export default connect(mapStateToProps)(CartResume);

const styles = {

    weightValueLabel: {
        paddingLeft: 30,
        paddingRight: 30,
        color: 'white',
        fontWeight: 'bold',
        fontSize: 50,
        borderWidth: 3,
        borderRadius: 20,
        padding: 10,
        textAlign: 'right',
        marginLeft: 15,
        marginRight: 15,
        backgroundColor: '#2E9DD6'
    },
    weightUnit: {
        color: 'white',
        fontSize: 30,
        textAlign: 'right',
        marginLeft: 15,
        marginRight: 25
    },
    powered: {
        color: 'white',
        fontSize: 20,
        textAlign: 'center',
        marginBottom: 20
    }
}