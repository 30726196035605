import { TOAST } from "../../utils/Helpers"

const initState = {
    userOrders: [],
    order_info: [],
    order: [],
    orderToEdit: null,
    orderToPrint: null,
    coupon: null
}
const OrderReducer = (state = initState, action) => {

    switch (action.type) {

        case "SET_COUPON":
            return {
                ...state,
                coupon: action.coupon
            }

        case "GET_USER_ORDERS":
            return {
                ...state,
                userOrders: action.payload.orders
            }
        case "GET_ORDER_INFO":
            return {
                ...state,
                order_info: action.payload.order_info,
            }
        case 'CREATE_ORDER':
            return {
                ...state,
                order: action.payload,
            };
        case 'SELECTED_ORDER':
            return {
                ...state,
                orderToEdit: action.payload.orderToEdit,
            };
        case 'SET_ORDER_TO_PRINT':
            return {
                ...state,
                orderToPrint: action.order,
            };
        default: return state;
    }

}


export default OrderReducer;