import { React, useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { geocodeByAddress } from 'react-google-places-autocomplete';
import { url } from '../../config/BaseUrl';
import { connect, useDispatch, useSelector } from 'react-redux'
import poweredByGoogle from '../../assets/images/powerd-by-google.png'
import { CircularProgress, Dialog, Grid } from '@material-ui/core';
import { TOAST } from '../../utils/Helpers';
import SelectComponent from '../cards/SelectComponent';
import { GetUserCategoriesAction } from '../../store/actions/CategoriesActions';

const NewProduct = ({ hideModal, categories }) => {

  const [isShowAddresses, setShowAddresses] = useState(false)
  const [category, setCategory] = useState(null)
  const [name, setName] = useState("")
  const [isWeightProduct, setWeightProduct] = useState(false)
  const [kgPrice, setKgPrice] = useState("")
  const [price, setPrice] = useState("")
  const [barCode, setBarCode] = useState("")
  const [isAddingProduct, setAddingProduct] = useState(false)
  const shop = useSelector(state => state.auth.shops);
  const dispatch = useDispatch()

  function saveProduct(_data) {

    if (category === null) {
      TOAST("Veuillez choisir la catégorie", "error")
      return
    }

    if (name === "") {
      TOAST("Veuillez saisir le nom du produit", "error")
      return
    }

    if (isWeightProduct && (kgPrice === 0 || kgPrice === "")) {
      TOAST("Veuillez saisir le prix par kg", "error")
      return
    }

    if (!isWeightProduct && (price === 0 || price === "")) {
      TOAST("Veuillez saisir le prix", "error")
      return
    }

    let data = {
      category: category?._id,
      name,
      isProductWithWeight: isWeightProduct,
      pricePerKg: isWeightProduct ? kgPrice : null,
      productBarCodeNumber: barCode,
      shop: shop?._id,
      prices: isWeightProduct ? [] : [{ price: price }]
    }

    setAddingProduct(true)
    if (category.isNew) {
      addCategory(category.name, data)
      return
    }
    addProduct(data)
  }

  function resetStates() {

    setCategory(null)
    setName("")
    setWeightProduct(false)
    setKgPrice("")
    setPrice("")
    setBarCode("")
    setAddingProduct(false)
  }

  function addCategory(name, data) {

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    url.post("categories/", { name: name, shop: shop?._id }, { headers: headers })
      .then(response => {
        console.log(response)
        if (response.status === 200) {
          let idCategory = response.data._id
          addProduct({ ...data, category: idCategory })
        }
      })
      .catch(err => { console.log(err) });
  }

  function addProduct(data) {

    const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    url.post("products/", data, { headers: headers })
      .then(response => {
        if (response.status === 200) {
          TOAST("Produit ajouté avec succés", "success")
          dispatch(GetUserCategoriesAction());
          resetStates()
        }
      })
      .catch(err => { console.log(err) });
  }

  const categoriesSelectStyle = {
    control: styles => ({ ...styles, height: 12, fontSize: 14 }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#fafafa",
        fontSize: 14,
        color: isSelected ? 'red' : 'black',
        height: 35
      }
    },
  };

  return (
    <>
      <Grid>
        <div className="relative flex-auto">
          <div className='flex flex-row px-6 align-center justify-center'>
            <p className="text-[16px] pt-2" style={{ flex: 0.5 }}>Catégorie</p>
            <div style={{ flex: 1, marginLeft: -15 }}>
              <SelectComponent
                data={categories.map(category => ({ value: category, label: category?.name }))}
                selectItem={(value) => setCategory(value)}
                value={category === null ? null : { value: category, label: category?.name }}
                style={categoriesSelectStyle} />
            </div>

          </div>

          <div className='flex flex-row px-6 align-center justify-center mt-3'>
            <p className="text-[16px] pt-2" style={{ flex: 0.5 }}>Nom du produit</p>
            <input
              style={{ flex: 1 }}
              type="text"
              className="form-control h-12 border border-gray-700  px-4 text-md bg-white"
              placeholder="..."
              value={name}
              onChange={(e) => { setName(e.target.value) }}
            />
          </div>

          <div className='flex flex-row px-6 align-center justify-center mt-3'>
            <p className="text-[16px]" style={{ flex: 0.5 }}>Produit pesable</p>
            <div style={{
              flex: 1,
              marginTop: 5
            }}>
              <input
                type="checkbox"
                className='h-[17px] w-[17px]'
                checked={isWeightProduct}
                onChange={(e) => { setWeightProduct(e.target.checked) }}
              />
            </div>
          </div>

          <div className='flex flex-row px-6 align-center justify-center mt-3' style={{ opacity: isWeightProduct ? 1 : 0.3, pointerEvents: isWeightProduct ? null : 'none' }}>
            <p className="text-[16px] pt-2" style={{ flex: 0.5 }}>Prix par Kg*</p>
            <input
              style={{ flex: 1 }}
              type="number"
              className="form-control h-12 border border-gray-700  px-4 text-md bg-white"
              placeholder="..."
              value={kgPrice}
              onChange={(e) => { setKgPrice(e.target.value) }}
            />
          </div>

          <div className='flex flex-row px-6 align-center justify-center mt-3' style={{ opacity: !isWeightProduct ? 1 : 0.3, pointerEvents: !isWeightProduct ? null : 'none' }}>
            <p className="text-[16px] pt-2" style={{ flex: 0.5 }}>Prix*</p>
            <input
              style={{ flex: 1 }}
              type="number"
              className="form-control h-12 border border-gray-700  px-4 text-md bg-white"
              placeholder="..."
              value={price}
              onChange={(e) => { setPrice(e.target.value) }}
            />
          </div>

          <div className='flex flex-row px-6 align-center justify-center mt-3'>
            <p className="text-[16px] pt-2" style={{ flex: 0.5 }}>Numéro Barcode</p>
            <input
              style={{ flex: 1 }}
              type="text"
              className="form-control h-12 border border-gray-700  px-4 text-md bg-white"
              placeholder="..."
              value={barCode}
              onChange={(e) => { setBarCode(e.target.value) }}
            />
          </div>

        </div>
      </Grid>

      <div className="flex flex-col  mt-3 md:flex-row md:items-end md:justify-end border-t border-solid border-slate-200">
        <button
          className="text-black bg-gray-200 w-full background-transparent font-semibold uppercase text-xl py-5 outline-none focus:outline-none ease-linear transition-all duration-150"
          type="button"
          disabled={isAddingProduct}
          onClick={() => hideModal()}>
          Fermer
        </button>
        <button
          className="bg-primary w-full text-white font-semibold uppercase text-xl py-5 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
          type="button"
          disabled={isAddingProduct}
          onClick={() => saveProduct()}
        >
          {isAddingProduct
            ? <CircularProgress size={30} style={{ color: 'white' }} />
            : "Enregistrer"
          }
        </button>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    categories: state.category.categories
  }
}

export default connect(mapStateToProps)(NewProduct);
