import React, { useEffect } from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import RootReducer from './store/reducers/RootReducers';
import App from "./App";
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const stateSyncMiddleware = createStateSyncMiddleware({});
const middleware = [thunk, stateSyncMiddleware];
const store = createStore(
  RootReducer,
  applyMiddleware(...middleware)
);

initMessageListener(store);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

