import React, { useState, useEffect } from 'react'
import CreateOrder from '../modals/CreateOrder'
import { useSelector, useDispatch, connect } from 'react-redux';
import { setItemToEdit } from '../../store/actions/CartActions'
import { updateCartItems } from '../../store/actions/CartActions'
import CartItem from './CartItem'
import { CART_SUBTOTAL, CART_TOTAL, GET_DELIVERY_PRICE, GET_MIN_ORDER_PRICE, SUBTOTAL_COUPON, TOAST } from '../../utils/Helpers'
import { Alert, Dialog, DialogContent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { wait } from '@testing-library/user-event/dist/utils';
import { DialogActions, DialogTitle } from '@material-ui/core';

const Cart = ({ setShowpanier, orderToEdit, deliveryMethod, updateBarCodeFocus, mobile, selectedAddress, setShowPayModal, table, resetTable, coupon, resetAllStates, cart, waiter, hideModal, isShowCartModal }) => {

  const [minOrderPrice, setMinOrderPrice] = useState(null)
  const [deliveryPrice, setDeliveryPrice] = useState(null)
  const parameters = useSelector(state => state.parameters.parameters);
  const dispatch = useDispatch();

  useEffect(() => {
  }, [orderToEdit])

  useEffect(() => {
    getDeliveryPrice()
    getMinOrderPrice()
  }, [deliveryMethod, selectedAddress]);

  async function getMinOrderPrice() {

    let minOrderPrice = await GET_MIN_ORDER_PRICE(deliveryMethod, parameters, selectedAddress?.address)
    setMinOrderPrice(minOrderPrice)
  }

  async function getDeliveryPrice() {

    let deliveryPrice = GET_DELIVERY_PRICE(deliveryMethod, parameters, selectedAddress?.address)
    setDeliveryPrice(deliveryPrice)
  }

  const isValidMinOrder = () => {
    return deliveryMethod === "inplace"
      ? true
      : (minOrderPrice === null
        ? false
        : (minOrderPrice - CART_TOTAL(cart, null, null)) <= 0)
  }

  return (
    <div className={'flex flex-col items-center bg-white shadow-xl w-[25%] fixed h-[calc(100vh-65px)] right-0 bottom-0 z-39'}>

      <div class="w-full md:relative h-full z-39 flex flex-col items-center">
        <div>
          <h3 className='text-center text-md font-medium border-gray-200 w-full pt-5'> Panier ({cart.length})</h3>
        </div>

        <div className='w-full flex-1 px-3 mt-5 overflow-y-scroll h-[calc(100vh)] scrollbar-none'>
          {cart.map((item) => {
            return (
              <CartItem
                _item={item}
                removeCartItem={(id) => dispatch(updateCartItems(cart.filter(cartItem => cartItem.id !== id)))}
                updateBarCodeFocus={(value) => updateBarCodeFocus(value)}
              />
            )
          })}
        </div>

        <div className='bg-gray-100 w-full mx-auto rounded-lg'>
          <div className='p-4'>
            {/* <div className='flex justify-between'>
              <span className='font-medium text-[15px]'>
                Sous total
              </span>
              <span className='font-semibold text-[15px]'>
                €{CART_SUBTOTAL(cart).toFixed(2)}
              </span>
            </div> */}
            {/* {coupon?.type === "Déduction" &&
              <div className='flex justify-between'>
                <span className='font-medium text-[18px]'>
                  Coupon ({coupon?.discount}%)
                </span>
                <span className='font-semibold text-[18px]'>
                  -€{-SUBTOTAL_COUPON(cart, coupon).toFixed(2)}
                </span>
              </div>
            }
            {deliveryMethod === "delivery" &&
              <div className='flex justify-between'>
                <span className='font-medium text-[18px]'>
                  Frais de livraison
                </span>
                <span className='font-semibold text-[18px]'>
                  €{deliveryPrice === 0 ? "Gratuit" : deliveryPrice}
                </span>
              </div>
            } */}
            <div className='flex justify-between'>
              <span className='font-bold text-xl'>
                TOTAL
              </span>
              <span className='font-bold text-xl'>
                €{CART_TOTAL(cart, deliveryPrice, coupon).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div className='text-center w-full'>
          <CreateOrder
            deliveryMethod={deliveryMethod}
            deliveryPrice={deliveryPrice}
            mobile={mobile}
            table={table}
            setShowPayModal={(value) => setShowPayModal(value)}
            resetTable={resetTable}
            selectedAddress={selectedAddress?.address}
            isValidMin={(deliveryMethod === "delivery" && selectedAddress && !(!isValidMinOrder() || deliveryPrice === null)) || (deliveryMethod !== "delivery" && isValidMinOrder())}
            resetAllStates={() => resetAllStates()}
          />
        </div>
      </div>
    </div >
  )
}

function mapStateToProps(state) {
  return {
    coupon: state.order.coupon,
    orderToEdit: state.order.orderToEdit,
    cart: state.cart.items,
    waiter: state.auth.waiter
  }
}

export default connect(mapStateToProps)(Cart);