/* eslint-disable default-case */
/* eslint-disable no-undef */
/* eslint-disable no-loop-func */
import axios from 'axios'
import { GOOGLE_API_KEY } from "../config/BaseUrl";
import { toast } from 'react-toastify';

export const CART_TOTAL = (items, deliveryPrice, coupon) => {

    var total = 0;
    total += parseFloat(deliveryPrice) || 0
    total += CART_SUBTOTAL(items)
    if (coupon) {
        let couponDiscount = 0
        let type = coupon.type
        switch (type) {
            case "Déduction":
                couponDiscount = -(total * coupon.discount * 0.01)
                break
        }
        console.log(total)
        console.log(couponDiscount)
        return total + couponDiscount
    } else {
        return total
    }
}

export const CART_SUBTOTAL = (items) => {
    var total = 0;

    for (var i = 0; i < items.length; i++) {

        total += items[i].quantity * items[i].price

        items[i].baseChoices?.map(item => {
            total += items[i].quantity * item.price
        })

        items[i].productBaseChoices?.map(item => {
            total += items[i].quantity * item.price
        })

        items[i].ingredients?.map(ingredient => {
            total += items[i].quantity * (ingredient.prices.length === 0
                ? 0
                : (items[i].size === "" || items[i].size == null
                    ? ingredient.prices[0]?.price
                    : ingredient.prices.filter(price => price.size?.name === items[i].size?.name)[0]?.price || 0)) * (ingredient.quantity || 1)
        })
    }
    return total
}

export const SUBTOTAL_COUPON = (items, coupon) => {
    var total = 0;
    for (var i = 0; i < items.length; i++) {
        total += items[i].quantity * items[i].price

        items[i].baseChoices && items[i].baseChoices.map(item => {
            total += items[i].quantity * item.price
        })

        items[i].productBaseChoices && items[i].productBaseChoices.map(item => {
            total += items[i].quantity * item.price
        })

        items[i].ingredients && items[i].ingredients.map(ingredient => {
            total += items[i].quantity * (ingredient.prices.length == 0
                ? 0
                : (items[i].size == "" || items[i].size == null
                    ? ingredient.prices[0].price
                    : ingredient.prices.filter(price => price.size.name === items[i].size?.name)[0]?.price || 0)) * (ingredient.quantity || 1)
        })
    }

    let couponDiscount = 0
    let type = coupon.type
    switch (type) {
        case "Déduction":
            couponDiscount = -(total * coupon.discount * 0.01)
            break
    }
    return couponDiscount
}

export const GET_CART_ITEM_TOTAL = (item) => {

    let total = item.quantity * item.price
    item.baseChoices.map(baseChoice => {
        total += item.quantity * baseChoice.price
    })
    item.productBaseChoices.map(baseChoice => {
        total += item.quantity * baseChoice.price
    })
    item.ingredients.forEach(supplement => {
        let price = !item.withSizes
            ? supplement.prices[0]?.price
            : supplement.prices.filter(price => price.size?.name === item.size?.name)[0]?.price
        total += item.quantity * ((price || 0) * (supplement.quantity ?? 1))
    })
    return total
}

export const GET_MIN_ORDER_PRICE = (deliveryMethod, parameters, currentAddress) => {
    let deliveryType = parameters.filter(param => param.name === "delivery_type")[0]
    if (deliveryMethod === "pickup" || deliveryMethod === "inplace") {
        return parameters.filter(param => param.name === "min_click_collect_order_price")[0]?.value[0]
    } else if (deliveryType?.type === "free_delivery") {
        if (deliveryType?.value?.length === 0) {
            return null
        } else {
            let distances = deliveryType?.value?.map(item => JSON.parse(item))
            let price = 0
            let distanceKm = (currentAddress?.distance / 1000).toFixed(1)
            distances.map((item) => {
                if (distanceKm >= parseInt(item.from) && distanceKm < parseInt(item.to)) {
                    price = item.price
                }
            })
            return price === 0 ? null : price
        }
    } else {
        return null
    }
}

export const GET_DELIVERY_PRICE = (deliveryMethod, parameters, currentAddress) => {
    if (deliveryMethod === "pickup" || deliveryMethod === "inplace") {
        return null
    } else {
        let parameter = parameters.filter(param => param.name === "delivery_type")[0]
        let distanceKm = (currentAddress?.distance / 1000).toFixed(1)
        let distances = parameter?.value.map(item => JSON.parse(item))
        switch (parameter?.type) {
            case "free_delivery":
                let cost = 0
                distances.map((item) => {
                    if (parseFloat(distanceKm) >= parseFloat(item.from) && distanceKm < parseFloat(item.to)) {
                        cost = item.cost || 0
                    }
                })
                return cost
            default:
                break;
        }
    }
}

export const GET_DISTANCE_KM = async (data) => {
    let duration = (
        data.rows.length > 0
        && data.rows[0].elements.length > 0
        && data.rows[0].elements[0].duration
        && data.rows[0].elements[0].duration.value
    ) || null
    let distance = (
        data.rows.length > 0
        && data.rows[0].elements.length > 0
        && data.rows[0].elements[0].distance
        && data.rows[0].elements[0].distance.value
    ) || null
    return { distance: distance, duration: duration }
}

export const GET_COORDINATES_GEOCODE = async (address) => {
    var result = null
    let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + GOOGLE_API_KEY
    await axios.get(url)
        .then(function (response) {
            result = response.data
        })
        .catch(function (error) {
            result = error
        });
    return result
}

export const TOAST = async (message, type) => {
    toast(message, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "light",
        type: type
    });
}