import { React, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import 'moment/locale/fr'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import OrderBill from './OrderBill';
import { GetOrderInfoAction } from '../../store/actions/OrderActions';

const OrderDetails = ({ id, isRoomDisplay, printerParameters }) => {

  const order_info = useSelector((state) => state.order.order_info);
  const [isShowBillModal, setShowBillModal] = useState(false);
  console.log("order_info", order_info)
  const orderInfoDispatch = useDispatch();

  const showData = () => {
    orderInfoDispatch(GetOrderInfoAction(id));
    setShowBillModal(true);
  };

  return (
    <>
      <FontAwesomeIcon
        onClick={() => showData(true)}
        className='cursor-pointer'
        icon={faFileInvoice}
        style={{ color: '#000', height: '25px', width: '25px' }}
      />

      <Dialog
        open={isShowBillModal}
        maxWidth='lg'
        PaperProps={{
          style: {
            minHeight: '90%'
          }
        }}
        onClose={() => setShowBillModal(false)}
        className="modalWrapper">
        <DialogTitle className='flex justify-end'>
          <FontAwesomeIcon onClick={() => setShowBillModal(false)} icon={faClose} style={{ color: "#000", height: 25, width: 25 }} />
        </DialogTitle>
        <DialogContent>
          <OrderBill
            printerConnexionMode={printerParameters.printerConnexionMode}
            printerEthernetIpAddress={printerParameters.printerEthernetIpAddress}
            paperSize={printerParameters.receiptPaperSize}
            hideModal={setShowBillModal}
            order={order_info}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

function mapStateToProps(state) {
  return {
    printerParameters: state.parameters.printerParameters,
    waiter: state.auth.waiter
  }
}

export default connect(mapStateToProps)(OrderDetails);


